import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '~/store/index'
import Media from '~/models/Media'

const initState = () => ({
	pendingMedias: [] as Media[],
})
export const state = initState
export type MediaModuleState = ReturnType<typeof state>

export const mutations: MutationTree<MediaModuleState> = {
	setPendingMedia(state, medias) {
		state.pendingMedias = medias
	},
	pushPendingMedia(state, media: Media) {
		state.pendingMedias.push(media)
	},
	removePendingMedia(state, id) {
		const index = state.pendingMedias.findIndex((media) => media.id === id)
		if (index > -1) {
			state.pendingMedias.splice(index, 1)
		}
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<MediaModuleState, RootState> = {
	clearPendingMedias({ commit }) {
		commit('setPendingMedia', [])
	},

	async prepareFile({ commit, dispatch }, file: File) {
		try {
			const res = await this.$repositories.media.upload(file)
			const { data } = res
			data.url = file
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				data.url = reader.result
				const parsedResults = this.$apiModel.media.parseMedia(data, {
					pending: true,
				})
				commit('pushPendingMedia', parsedResults)
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async reset({ commit }) {
		await commit('reset')
	},
}

export const getters: GetterTree<MediaModuleState, RootState> = {
	pendingMedias: (state) => {
		return state.pendingMedias
	},
}
