export default class SlugService {
	static normalize(text: string): string {
		const decomposeCombinedGraphemes: string = 'NFD'
		const diacriticalMarks: RegExp = /[\u0300-\u036f]/g
		const notContainingAnyAlphabetChar: RegExp = /[^\p{L}\p{N}\s]+/gu
		return text
			.normalize(decomposeCombinedGraphemes)
			.replace(diacriticalMarks, '')
			.replace(notContainingAnyAlphabetChar, '')
			.trim()
			.replaceAll(' ', '-')
			.toLowerCase()
	}
}
