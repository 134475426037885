import { Page } from '../../models/PaginatedItems'
import { BaseRepository } from '../baseRepository'
import { ApiMedia } from '../media/apitype'
import { MediaEntityMapper } from '../media/entityMapper'
import { ApiPage, toPage } from '../shared/pagination'
import Media from '~/models/Media'

export class ItineraryRepository extends BaseRepository {
	static resource = 'v1/itineraries'

	static async addMedia(itineraryId: string, mediaId: string): Promise<Media> {
		const response: ApiMedia | undefined = await this.post(
			`${this.resource}/${itineraryId}/medias/`,
			{
				media_id: mediaId,
			}
		)
		return MediaEntityMapper.toMedia(response!)
	}

	static async getMedias(itineraryId: string): Promise<Page<Media>> {
		const response: ApiPage<ApiMedia> | undefined = await this.get(
			`${this.resource}/${itineraryId}/medias/`
		)
		const medias = MediaEntityMapper.toMedias(response!.results)
		return toPage(response!, medias)
	}

	static async deleteMedia(itineraryId: string, mediaId: string): Promise<void> {
		await this.delete(`${this.resource}/${itineraryId}/medias/${mediaId}`)
	}
}
