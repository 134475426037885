import { BaseRepository } from '../baseRepository'
import { ApiItinerary } from '../itinerary/apiTypes'
import { ItineraryEntityMapper } from '../itinerary/entityMapper'
import { ApiPage, toPage } from '../shared/pagination'
import ProfileApiMapper from './apiMapper'
import { ApiAvailability, ApiProfile, ApiProfileDestination } from './apiTypes'
import { ProfileEntityMapper } from './entityMapper'
import Traveler from '~/models/Traveler'
import { Profile } from '~/models/Profile'
import { Itinerary2, ItineraryStatus } from '~/models/Itinerary'
import { Page } from '~/models/PaginatedItems'
import { ProfileDestination } from '~/models/ProfileDestination'

export class ProfileRepository extends BaseRepository {
	static resource = 'v1/profiles'

	static async getProfile(id: string): Promise<Profile> {
		const response: ApiProfile = await this.get(`${this.resource}/${id}/`)
		return ProfileEntityMapper.toProfile(response!)
	}

	static async getTraveler(id: string): Promise<Traveler> {
		const response: ApiProfile = await this.get(`${this.resource}/${id}/`)
		return ProfileEntityMapper.toTraveler(response!)
	}

	static async updateProfile(id: string, profile: Profile): Promise<Profile> {
		const response: ApiProfile | undefined = await this.patch(
			`${this.resource}/${id}/`,
			ProfileApiMapper.toApiUpdateProfile(profile)
		)
		return ProfileEntityMapper.toProfile(response!)
	}

	static async getItineraries({
		id,
		page,
		status = 'all',
		destinationId = '',
	}: {
		id: string
		page: number
		status?: ItineraryStatus
		destinationId?: string
	}): Promise<Page<Itinerary2>> {
		const params: Record<string, any> = {
			travel_status: status,
			page,
			page_size: 16,
		}
		if (destinationId) params.destination_id = destinationId
		const response: ApiPage<ApiItinerary> = await this.get(
			`${this.resource}/${id}/itineraries/`,
			params
		)
		const itineraries = ItineraryEntityMapper.toItineraries(response!.results)
		return toPage(response!, itineraries)
	}

	static async getWishList(profileId: string): Promise<ProfileDestination[]> {
		const response: ApiProfileDestination[] = await this.get(
			`${this.resource}/${profileId}/destinations/wishlist/`
		)
		return ProfileEntityMapper.toProfileDestinations(response)
	}

	static async getVisitedDestinations(
		profileId: string,
		hasItineraries?: boolean
	): Promise<ProfileDestination[]> {
		let endpoint: string = `${this.resource}/${profileId}/destinations/visited/`
		if (hasItineraries !== undefined) {
			endpoint += `?has_trips=${hasItineraries}`
		}
		const response: ApiProfileDestination[] = await this.get(endpoint)
		return ProfileEntityMapper.toProfileDestinations(response)
	}

	static async checkUsernameAvailability(username: string): Promise<boolean> {
		const params = { username: ProfileApiMapper.toApiAvailability(username) }
		const response: ApiAvailability = await this.get(`auth/user/check/`, params)
		return ProfileEntityMapper.toAvailable(response)
	}

	static async addWishlist(userId: string, wishlist: Array<string>): Promise<void> {
		await this.post(`${this.resource}/${userId}/destinations/wishlist/`, {
			destination_ids: wishlist,
		})
	}

	static async addVisitedList(userId: string, visitedList: Array<string>): Promise<void> {
		await this.post(`${this.resource}/${userId}/destinations/visited/`, {
			destination_ids: visitedList,
		})
	}

	static async removeWishlistDestination(userId: string, destinationId: string): Promise<void> {
		await this.delete(`${this.resource}/${userId}/destinations/wishlist/${destinationId}`)
	}

	static async removeVisitedDestination(userId: string, destinationId: string): Promise<void> {
		await this.delete(`${this.resource}/${userId}/destinations/visited/${destinationId}`)
	}
}
