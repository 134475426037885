



















import Vue from 'vue'
import { mapGetters } from 'vuex'
import DeleteDialog from '~/components/UI/DeleteDialog.vue'
import Traveler from '~/models/Traveler'
import TravelerMiniCard from '~/components/context/items/itineraries/TravelerMiniCard.vue'
import PIconClose from '~/components/PassporterUI/Icon/PIconClose.vue'

export default Vue.extend({
	name: 'TravelersList',
	components: {
		PIconClose,
		TravelerMiniCard,
		DeleteDialog,
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters({
			itinerary: 'itinerary/itinerary',
		}),
		travelers(): Traveler[] | undefined {
			return this.itinerary?.travelers
		},
	},
	methods: {
		async deleteTraveler(traveler: Traveler) {
			try {
				await this.$store.dispatch('itinerary/deleteTraveler', {
					travelerId: traveler.id,
				})
				await this.$store.dispatch('alerts/setAlert', {
					type: 'broken-heart',
					text: this.$t('Toast_itinerary_deleted_traveller_from_itinerary'),
				})
			} catch (e: any) {}
		},
		openTravelerView(traveler: Traveler) {
			this.$store.dispatch('traveler/openTraveler', { traveler })
		},
	},
})
