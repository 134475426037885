import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6fe59b5a = () => interopDefault(import('../src/routes/home.vue' /* webpackChunkName: "routes/home" */))
const _5fe9d581 = () => interopDefault(import('../src/routes/profile/index.vue' /* webpackChunkName: "routes/profile/index" */))
const _7a2f2fc0 = () => interopDefault(import('../src/routes/auth/login/index.vue' /* webpackChunkName: "routes/auth/login/index" */))
const _077d2137 = () => interopDefault(import('../src/routes/auth/signup/index.vue' /* webpackChunkName: "routes/auth/signup/index" */))
const _7685d32e = () => interopDefault(import('../src/routes/frame/blog-wizard.vue' /* webpackChunkName: "routes/frame/blog-wizard" */))
const _5df75ac6 = () => interopDefault(import('../src/routes/itineraries/create.vue' /* webpackChunkName: "routes/itineraries/create" */))
const _401dccaa = () => interopDefault(import('../src/routes/profile/edit.vue' /* webpackChunkName: "routes/profile/edit" */))
const _7a589ef6 = () => interopDefault(import('../src/routes/user/restore_password.vue' /* webpackChunkName: "routes/user/restore_password" */))
const _60e31b66 = () => interopDefault(import('../src/routes/frame/itineraries/demo.vue' /* webpackChunkName: "routes/frame/itineraries/demo" */))
const _3351320f = () => interopDefault(import('../src/routes/frame/blog-itinerary-wizard/lead/_lead_id/destination/_destination_id/_title.vue' /* webpackChunkName: "routes/frame/blog-itinerary-wizard/lead/_lead_id/destination/_destination_id/_title" */))
const _95562906 = () => interopDefault(import('../src/routes/destinations/discovery/_destination_id.vue' /* webpackChunkName: "routes/destinations/discovery/_destination_id" */))
const _7475e987 = () => interopDefault(import('../src/routes/frame/itineraries/_id.vue' /* webpackChunkName: "routes/frame/itineraries/_id" */))
const _c758da34 = () => interopDefault(import('../src/routes/profile/itineraries/_category.vue' /* webpackChunkName: "routes/profile/itineraries/_category" */))
const _336631c2 = () => interopDefault(import('../src/routes/destinations/_destination_id/index.vue' /* webpackChunkName: "routes/destinations/_destination_id/index" */))
const _588b47bc = () => interopDefault(import('../src/routes/discovery/_block_id.vue' /* webpackChunkName: "routes/discovery/_block_id" */))
const _ddb3811e = () => interopDefault(import('../src/routes/docs/_slug.vue' /* webpackChunkName: "routes/docs/_slug" */))
const _49d1a446 = () => interopDefault(import('../src/routes/download/_platform.vue' /* webpackChunkName: "routes/download/_platform" */))
const _5be007ba = () => interopDefault(import('../src/routes/experiences/_spot_id.vue' /* webpackChunkName: "routes/experiences/_spot_id" */))
const _6ab2ac40 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/index.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/index" */))
const _436586e9 = () => interopDefault(import('../src/routes/profiles/_profile_id/index.vue' /* webpackChunkName: "routes/profiles/_profile_id/index" */))
const _f00dd6d6 = () => interopDefault(import('../src/routes/spots/_spot_id/index.vue' /* webpackChunkName: "routes/spots/_spot_id/index" */))
const _2371b7e9 = () => interopDefault(import('../src/routes/destinations/_destination_id/map.vue' /* webpackChunkName: "routes/destinations/_destination_id/map" */))
const _1a2f459c = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/bookings.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/bookings" */))
const _07ae8528 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/edit.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/edit" */))
const _34e42274 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/products.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/products" */))
const _3aeabf58 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/thank_you.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/thank_you" */))
const _4bfdb5cc = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/travelers.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/travelers" */))
const _9bdebf68 = () => interopDefault(import('../src/routes/spots/_spot_id/experiences.vue' /* webpackChunkName: "routes/spots/_spot_id/experiences" */))
const _0d637225 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/groups/create.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/groups/create" */))
const _3e5ba528 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/notes/create.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/notes/create" */))
const _6280bcd8 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/overview/preview.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/overview/preview" */))
const _1d5e826e = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/overview/shared.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/overview/shared" */))
const _75e08f6a = () => interopDefault(import('../src/routes/destinations/_destination_id/guides/_guide_id/index.vue' /* webpackChunkName: "routes/destinations/_destination_id/guides/_guide_id/index" */))
const _1dfe7b87 = () => interopDefault(import('../src/routes/destinations/_destination_id/landmark/_landmark_id.vue' /* webpackChunkName: "routes/destinations/_destination_id/landmark/_landmark_id" */))
const _d9634c46 = () => interopDefault(import('../src/routes/destinations/_destination_id/spots/_spot_id/index.vue' /* webpackChunkName: "routes/destinations/_destination_id/spots/_spot_id/index" */))
const _04be2f46 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/destinations/_destination_id/index.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/destinations/_destination_id/index" */))
const _2a1ec608 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/spots/_spot_id/index.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/spots/_spot_id/index" */))
const _614f744e = () => interopDefault(import('../src/routes/profiles/_profile_id/itineraries/_category.vue' /* webpackChunkName: "routes/profiles/_profile_id/itineraries/_category" */))
const _5e14ddd0 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/destinations/_destination_id/map.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/destinations/_destination_id/map" */))
const _18fce99e = () => interopDefault(import('../src/routes/destinations/_destination_id/guides/_guide_id/spots/_spot_id/index.vue' /* webpackChunkName: "routes/destinations/_destination_id/guides/_guide_id/spots/_spot_id/index" */))
const _3090e304 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/destinations/_destination_id/guides/_guide_id/index.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/destinations/_destination_id/guides/_guide_id/index" */))
const _4ac095ae = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/destinations/_destination_id/landmark/_landmark_id.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/destinations/_destination_id/landmark/_landmark_id" */))
const _085cded6 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/destinations/_destination_id/spots/_spot_id/index.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/destinations/_destination_id/spots/_spot_id/index" */))
const _329f1150 = () => interopDefault(import('../src/routes/itineraries/_itinerary_id/destinations/_destination_id/guides/_guide_id/spots/_spot_id/index.vue' /* webpackChunkName: "routes/itineraries/_itinerary_id/destinations/_destination_id/guides/_guide_id/spots/_spot_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ca/home",
    component: _6fe59b5a,
    name: "home___ca"
  }, {
    path: "/ca/profile",
    component: _5fe9d581,
    name: "profile___ca"
  }, {
    path: "/de/home",
    component: _6fe59b5a,
    name: "home___de"
  }, {
    path: "/de/profile",
    component: _5fe9d581,
    name: "profile___de"
  }, {
    path: "/el/home",
    component: _6fe59b5a,
    name: "home___el"
  }, {
    path: "/el/profile",
    component: _5fe9d581,
    name: "profile___el"
  }, {
    path: "/en/home",
    component: _6fe59b5a,
    name: "home___en"
  }, {
    path: "/en/profile",
    component: _5fe9d581,
    name: "profile___en"
  }, {
    path: "/es/home",
    component: _6fe59b5a,
    name: "home___es"
  }, {
    path: "/es/profile",
    component: _5fe9d581,
    name: "profile___es"
  }, {
    path: "/fr/home",
    component: _6fe59b5a,
    name: "home___fr"
  }, {
    path: "/fr/profile",
    component: _5fe9d581,
    name: "profile___fr"
  }, {
    path: "/it/home",
    component: _6fe59b5a,
    name: "home___it"
  }, {
    path: "/it/profile",
    component: _5fe9d581,
    name: "profile___it"
  }, {
    path: "/ja/home",
    component: _6fe59b5a,
    name: "home___ja"
  }, {
    path: "/ja/profile",
    component: _5fe9d581,
    name: "profile___ja"
  }, {
    path: "/ko/home",
    component: _6fe59b5a,
    name: "home___ko"
  }, {
    path: "/ko/profile",
    component: _5fe9d581,
    name: "profile___ko"
  }, {
    path: "/nl/home",
    component: _6fe59b5a,
    name: "home___nl"
  }, {
    path: "/nl/profile",
    component: _5fe9d581,
    name: "profile___nl"
  }, {
    path: "/pl/home",
    component: _6fe59b5a,
    name: "home___pl"
  }, {
    path: "/pl/profile",
    component: _5fe9d581,
    name: "profile___pl"
  }, {
    path: "/pt-br/home",
    component: _6fe59b5a,
    name: "home___pt-br"
  }, {
    path: "/pt-br/profile",
    component: _5fe9d581,
    name: "profile___pt-br"
  }, {
    path: "/pt/home",
    component: _6fe59b5a,
    name: "home___pt"
  }, {
    path: "/pt/profile",
    component: _5fe9d581,
    name: "profile___pt"
  }, {
    path: "/ru/home",
    component: _6fe59b5a,
    name: "home___ru"
  }, {
    path: "/ru/profile",
    component: _5fe9d581,
    name: "profile___ru"
  }, {
    path: "/tr/home",
    component: _6fe59b5a,
    name: "home___tr"
  }, {
    path: "/tr/profile",
    component: _5fe9d581,
    name: "profile___tr"
  }, {
    path: "/zh/home",
    component: _6fe59b5a,
    name: "home___zh"
  }, {
    path: "/zh/profile",
    component: _5fe9d581,
    name: "profile___zh"
  }, {
    path: "/ca/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___ca"
  }, {
    path: "/ca/auth/signup",
    component: _077d2137,
    name: "auth-signup___ca"
  }, {
    path: "/ca/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___ca"
  }, {
    path: "/ca/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___ca"
  }, {
    path: "/ca/profile/edit",
    component: _401dccaa,
    name: "profile-edit___ca"
  }, {
    path: "/ca/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___ca"
  }, {
    path: "/de/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___de"
  }, {
    path: "/de/auth/signup",
    component: _077d2137,
    name: "auth-signup___de"
  }, {
    path: "/de/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___de"
  }, {
    path: "/de/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___de"
  }, {
    path: "/de/profile/edit",
    component: _401dccaa,
    name: "profile-edit___de"
  }, {
    path: "/de/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___de"
  }, {
    path: "/el/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___el"
  }, {
    path: "/el/auth/signup",
    component: _077d2137,
    name: "auth-signup___el"
  }, {
    path: "/el/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___el"
  }, {
    path: "/el/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___el"
  }, {
    path: "/el/profile/edit",
    component: _401dccaa,
    name: "profile-edit___el"
  }, {
    path: "/el/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___el"
  }, {
    path: "/en/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___en"
  }, {
    path: "/en/auth/signup",
    component: _077d2137,
    name: "auth-signup___en"
  }, {
    path: "/en/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___en"
  }, {
    path: "/en/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___en"
  }, {
    path: "/en/profile/edit",
    component: _401dccaa,
    name: "profile-edit___en"
  }, {
    path: "/en/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___en"
  }, {
    path: "/es/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___es"
  }, {
    path: "/es/auth/signup",
    component: _077d2137,
    name: "auth-signup___es"
  }, {
    path: "/es/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___es"
  }, {
    path: "/es/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___es"
  }, {
    path: "/es/profile/edit",
    component: _401dccaa,
    name: "profile-edit___es"
  }, {
    path: "/es/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___es"
  }, {
    path: "/fr/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/signup",
    component: _077d2137,
    name: "auth-signup___fr"
  }, {
    path: "/fr/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___fr"
  }, {
    path: "/fr/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___fr"
  }, {
    path: "/fr/profile/edit",
    component: _401dccaa,
    name: "profile-edit___fr"
  }, {
    path: "/fr/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___fr"
  }, {
    path: "/it/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___it"
  }, {
    path: "/it/auth/signup",
    component: _077d2137,
    name: "auth-signup___it"
  }, {
    path: "/it/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___it"
  }, {
    path: "/it/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___it"
  }, {
    path: "/it/profile/edit",
    component: _401dccaa,
    name: "profile-edit___it"
  }, {
    path: "/it/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___it"
  }, {
    path: "/ja/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___ja"
  }, {
    path: "/ja/auth/signup",
    component: _077d2137,
    name: "auth-signup___ja"
  }, {
    path: "/ja/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___ja"
  }, {
    path: "/ja/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___ja"
  }, {
    path: "/ja/profile/edit",
    component: _401dccaa,
    name: "profile-edit___ja"
  }, {
    path: "/ja/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___ja"
  }, {
    path: "/ko/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___ko"
  }, {
    path: "/ko/auth/signup",
    component: _077d2137,
    name: "auth-signup___ko"
  }, {
    path: "/ko/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___ko"
  }, {
    path: "/ko/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___ko"
  }, {
    path: "/ko/profile/edit",
    component: _401dccaa,
    name: "profile-edit___ko"
  }, {
    path: "/ko/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___ko"
  }, {
    path: "/nl/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___nl"
  }, {
    path: "/nl/auth/signup",
    component: _077d2137,
    name: "auth-signup___nl"
  }, {
    path: "/nl/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___nl"
  }, {
    path: "/nl/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___nl"
  }, {
    path: "/nl/profile/edit",
    component: _401dccaa,
    name: "profile-edit___nl"
  }, {
    path: "/nl/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___nl"
  }, {
    path: "/pl/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___pl"
  }, {
    path: "/pl/auth/signup",
    component: _077d2137,
    name: "auth-signup___pl"
  }, {
    path: "/pl/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___pl"
  }, {
    path: "/pl/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___pl"
  }, {
    path: "/pl/profile/edit",
    component: _401dccaa,
    name: "profile-edit___pl"
  }, {
    path: "/pl/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___pl"
  }, {
    path: "/pt-br/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___pt-br"
  }, {
    path: "/pt-br/auth/signup",
    component: _077d2137,
    name: "auth-signup___pt-br"
  }, {
    path: "/pt-br/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___pt-br"
  }, {
    path: "/pt-br/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___pt-br"
  }, {
    path: "/pt-br/profile/edit",
    component: _401dccaa,
    name: "profile-edit___pt-br"
  }, {
    path: "/pt-br/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___pt-br"
  }, {
    path: "/pt/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___pt"
  }, {
    path: "/pt/auth/signup",
    component: _077d2137,
    name: "auth-signup___pt"
  }, {
    path: "/pt/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___pt"
  }, {
    path: "/pt/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___pt"
  }, {
    path: "/pt/profile/edit",
    component: _401dccaa,
    name: "profile-edit___pt"
  }, {
    path: "/pt/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___pt"
  }, {
    path: "/ru/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___ru"
  }, {
    path: "/ru/auth/signup",
    component: _077d2137,
    name: "auth-signup___ru"
  }, {
    path: "/ru/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___ru"
  }, {
    path: "/ru/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___ru"
  }, {
    path: "/ru/profile/edit",
    component: _401dccaa,
    name: "profile-edit___ru"
  }, {
    path: "/ru/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___ru"
  }, {
    path: "/tr/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___tr"
  }, {
    path: "/tr/auth/signup",
    component: _077d2137,
    name: "auth-signup___tr"
  }, {
    path: "/tr/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___tr"
  }, {
    path: "/tr/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___tr"
  }, {
    path: "/tr/profile/edit",
    component: _401dccaa,
    name: "profile-edit___tr"
  }, {
    path: "/tr/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___tr"
  }, {
    path: "/zh/auth/login",
    component: _7a2f2fc0,
    name: "auth-login___zh"
  }, {
    path: "/zh/auth/signup",
    component: _077d2137,
    name: "auth-signup___zh"
  }, {
    path: "/zh/frame/blog-wizard",
    component: _7685d32e,
    name: "frame-blog-wizard___zh"
  }, {
    path: "/zh/itineraries/create",
    component: _5df75ac6,
    name: "itineraries-create___zh"
  }, {
    path: "/zh/profile/edit",
    component: _401dccaa,
    name: "profile-edit___zh"
  }, {
    path: "/zh/user/restore_password",
    component: _7a589ef6,
    name: "user-restore_password___zh"
  }, {
    path: "/ca/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___ca"
  }, {
    path: "/de/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___de"
  }, {
    path: "/el/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___el"
  }, {
    path: "/en/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___en"
  }, {
    path: "/es/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___es"
  }, {
    path: "/fr/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___fr"
  }, {
    path: "/it/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___it"
  }, {
    path: "/ja/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___ja"
  }, {
    path: "/ko/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___ko"
  }, {
    path: "/nl/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___nl"
  }, {
    path: "/pl/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___pl"
  }, {
    path: "/pt-br/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___pt-br"
  }, {
    path: "/pt/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___pt"
  }, {
    path: "/ru/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___ru"
  }, {
    path: "/tr/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___tr"
  }, {
    path: "/zh/frame/itineraries/demo",
    component: _60e31b66,
    name: "frame-itineraries-demo___zh"
  }, {
    path: "/ca/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___ca"
  }, {
    path: "/de/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___de"
  }, {
    path: "/el/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___el"
  }, {
    path: "/en/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___en"
  }, {
    path: "/es/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___es"
  }, {
    path: "/fr/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___fr"
  }, {
    path: "/it/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___it"
  }, {
    path: "/ja/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___ja"
  }, {
    path: "/ko/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___ko"
  }, {
    path: "/nl/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___nl"
  }, {
    path: "/pl/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___pl"
  }, {
    path: "/pt-br/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___pt-br"
  }, {
    path: "/pt/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___pt"
  }, {
    path: "/ru/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___ru"
  }, {
    path: "/tr/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___tr"
  }, {
    path: "/zh/frame/blog-itinerary-wizard/lead/:lead_id?/destination/:destination_id?/:title?",
    component: _3351320f,
    name: "frame-blog-itinerary-wizard-lead-lead_id-destination-destination_id-title___zh"
  }, {
    path: "/ca/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___ca"
  }, {
    path: "/ca/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___ca"
  }, {
    path: "/ca/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___ca"
  }, {
    path: "/de/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___de"
  }, {
    path: "/de/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___de"
  }, {
    path: "/de/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___de"
  }, {
    path: "/el/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___el"
  }, {
    path: "/el/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___el"
  }, {
    path: "/el/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___el"
  }, {
    path: "/en/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___en"
  }, {
    path: "/en/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___en"
  }, {
    path: "/en/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___en"
  }, {
    path: "/es/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___es"
  }, {
    path: "/es/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___es"
  }, {
    path: "/es/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___es"
  }, {
    path: "/fr/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___fr"
  }, {
    path: "/fr/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___fr"
  }, {
    path: "/fr/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___fr"
  }, {
    path: "/it/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___it"
  }, {
    path: "/it/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___it"
  }, {
    path: "/it/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___it"
  }, {
    path: "/ja/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___ja"
  }, {
    path: "/ja/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___ja"
  }, {
    path: "/ja/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___ja"
  }, {
    path: "/ko/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___ko"
  }, {
    path: "/ko/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___ko"
  }, {
    path: "/ko/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___ko"
  }, {
    path: "/nl/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___nl"
  }, {
    path: "/nl/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___nl"
  }, {
    path: "/nl/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___nl"
  }, {
    path: "/pl/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___pl"
  }, {
    path: "/pl/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___pl"
  }, {
    path: "/pl/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___pl"
  }, {
    path: "/pt-br/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___pt-br"
  }, {
    path: "/pt-br/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___pt-br"
  }, {
    path: "/pt-br/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___pt-br"
  }, {
    path: "/pt/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___pt"
  }, {
    path: "/pt/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___pt"
  }, {
    path: "/pt/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___pt"
  }, {
    path: "/ru/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___ru"
  }, {
    path: "/ru/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___ru"
  }, {
    path: "/ru/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___ru"
  }, {
    path: "/tr/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___tr"
  }, {
    path: "/tr/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___tr"
  }, {
    path: "/tr/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___tr"
  }, {
    path: "/zh/destinations/discovery/:destination_id?",
    component: _95562906,
    name: "destinations-discovery-destination_id___zh"
  }, {
    path: "/zh/frame/itineraries/:id?",
    component: _7475e987,
    name: "frame-itineraries-id___zh"
  }, {
    path: "/zh/profile/itineraries/:category?",
    component: _c758da34,
    name: "profile-itineraries-category___zh"
  }, {
    path: "/ca/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___ca"
  }, {
    path: "/ca/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___ca"
  }, {
    path: "/ca/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___ca"
  }, {
    path: "/ca/download/:platform?",
    component: _49d1a446,
    name: "download-platform___ca"
  }, {
    path: "/ca/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___ca"
  }, {
    path: "/ca/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___ca"
  }, {
    path: "/ca/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___ca"
  }, {
    path: "/de/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___de"
  }, {
    path: "/de/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___de"
  }, {
    path: "/de/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___de"
  }, {
    path: "/de/download/:platform?",
    component: _49d1a446,
    name: "download-platform___de"
  }, {
    path: "/de/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___de"
  }, {
    path: "/de/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___de"
  }, {
    path: "/de/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___de"
  }, {
    path: "/de/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___de"
  }, {
    path: "/el/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___el"
  }, {
    path: "/el/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___el"
  }, {
    path: "/el/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___el"
  }, {
    path: "/el/download/:platform?",
    component: _49d1a446,
    name: "download-platform___el"
  }, {
    path: "/el/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___el"
  }, {
    path: "/el/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___el"
  }, {
    path: "/el/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___el"
  }, {
    path: "/el/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___el"
  }, {
    path: "/en/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___en"
  }, {
    path: "/en/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___en"
  }, {
    path: "/en/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___en"
  }, {
    path: "/en/download/:platform?",
    component: _49d1a446,
    name: "download-platform___en"
  }, {
    path: "/en/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___en"
  }, {
    path: "/en/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___en"
  }, {
    path: "/en/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___en"
  }, {
    path: "/en/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___en"
  }, {
    path: "/es/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___es"
  }, {
    path: "/es/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___es"
  }, {
    path: "/es/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___es"
  }, {
    path: "/es/download/:platform?",
    component: _49d1a446,
    name: "download-platform___es"
  }, {
    path: "/es/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___es"
  }, {
    path: "/es/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___es"
  }, {
    path: "/es/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___es"
  }, {
    path: "/es/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___es"
  }, {
    path: "/fr/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___fr"
  }, {
    path: "/fr/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___fr"
  }, {
    path: "/fr/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___fr"
  }, {
    path: "/fr/download/:platform?",
    component: _49d1a446,
    name: "download-platform___fr"
  }, {
    path: "/fr/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___fr"
  }, {
    path: "/fr/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___fr"
  }, {
    path: "/fr/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___fr"
  }, {
    path: "/it/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___it"
  }, {
    path: "/it/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___it"
  }, {
    path: "/it/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___it"
  }, {
    path: "/it/download/:platform?",
    component: _49d1a446,
    name: "download-platform___it"
  }, {
    path: "/it/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___it"
  }, {
    path: "/it/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___it"
  }, {
    path: "/it/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___it"
  }, {
    path: "/it/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___it"
  }, {
    path: "/ja/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___ja"
  }, {
    path: "/ja/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___ja"
  }, {
    path: "/ja/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___ja"
  }, {
    path: "/ja/download/:platform?",
    component: _49d1a446,
    name: "download-platform___ja"
  }, {
    path: "/ja/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___ja"
  }, {
    path: "/ja/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___ja"
  }, {
    path: "/ja/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___ja"
  }, {
    path: "/ko/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___ko"
  }, {
    path: "/ko/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___ko"
  }, {
    path: "/ko/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___ko"
  }, {
    path: "/ko/download/:platform?",
    component: _49d1a446,
    name: "download-platform___ko"
  }, {
    path: "/ko/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___ko"
  }, {
    path: "/ko/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___ko"
  }, {
    path: "/ko/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___ko"
  }, {
    path: "/nl/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___nl"
  }, {
    path: "/nl/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___nl"
  }, {
    path: "/nl/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___nl"
  }, {
    path: "/nl/download/:platform?",
    component: _49d1a446,
    name: "download-platform___nl"
  }, {
    path: "/nl/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___nl"
  }, {
    path: "/nl/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___nl"
  }, {
    path: "/nl/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___nl"
  }, {
    path: "/pl/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___pl"
  }, {
    path: "/pl/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___pl"
  }, {
    path: "/pl/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___pl"
  }, {
    path: "/pl/download/:platform?",
    component: _49d1a446,
    name: "download-platform___pl"
  }, {
    path: "/pl/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___pl"
  }, {
    path: "/pl/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___pl"
  }, {
    path: "/pl/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___pl"
  }, {
    path: "/pt-br/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___pt-br"
  }, {
    path: "/pt-br/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___pt-br"
  }, {
    path: "/pt-br/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___pt-br"
  }, {
    path: "/pt-br/download/:platform?",
    component: _49d1a446,
    name: "download-platform___pt-br"
  }, {
    path: "/pt-br/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___pt-br"
  }, {
    path: "/pt-br/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___pt-br"
  }, {
    path: "/pt-br/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___pt-br"
  }, {
    path: "/pt/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___pt"
  }, {
    path: "/pt/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___pt"
  }, {
    path: "/pt/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___pt"
  }, {
    path: "/pt/download/:platform?",
    component: _49d1a446,
    name: "download-platform___pt"
  }, {
    path: "/pt/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___pt"
  }, {
    path: "/pt/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___pt"
  }, {
    path: "/pt/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___pt"
  }, {
    path: "/ru/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___ru"
  }, {
    path: "/ru/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___ru"
  }, {
    path: "/ru/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___ru"
  }, {
    path: "/ru/download/:platform?",
    component: _49d1a446,
    name: "download-platform___ru"
  }, {
    path: "/ru/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___ru"
  }, {
    path: "/ru/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___ru"
  }, {
    path: "/ru/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___ru"
  }, {
    path: "/tr/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___tr"
  }, {
    path: "/tr/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___tr"
  }, {
    path: "/tr/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___tr"
  }, {
    path: "/tr/download/:platform?",
    component: _49d1a446,
    name: "download-platform___tr"
  }, {
    path: "/tr/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___tr"
  }, {
    path: "/tr/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___tr"
  }, {
    path: "/tr/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___tr"
  }, {
    path: "/zh/destinations/:destination_id",
    component: _336631c2,
    name: "destinations-destination_id___zh"
  }, {
    path: "/zh/discovery/:block_id?",
    component: _588b47bc,
    name: "discovery-block_id___zh"
  }, {
    path: "/zh/docs/:slug?",
    component: _ddb3811e,
    name: "docs-slug___zh"
  }, {
    path: "/zh/download/:platform?",
    component: _49d1a446,
    name: "download-platform___zh"
  }, {
    path: "/zh/experiences/:spot_id?",
    component: _5be007ba,
    name: "experiences-spot_id___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id",
    component: _6ab2ac40,
    name: "itineraries-itinerary_id___zh"
  }, {
    path: "/zh/profiles/:profile_id",
    component: _436586e9,
    name: "profiles-profile_id___zh"
  }, {
    path: "/zh/spots/:spot_id",
    component: _f00dd6d6,
    name: "spots-spot_id___zh"
  }, {
    path: "/ca/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___ca"
  }, {
    path: "/ca/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___ca"
  }, {
    path: "/de/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___de"
  }, {
    path: "/de/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___de"
  }, {
    path: "/el/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___el"
  }, {
    path: "/el/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___el"
  }, {
    path: "/en/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___en"
  }, {
    path: "/en/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___en"
  }, {
    path: "/es/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___es"
  }, {
    path: "/es/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___es"
  }, {
    path: "/fr/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___fr"
  }, {
    path: "/fr/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___fr"
  }, {
    path: "/it/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___it"
  }, {
    path: "/it/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___it"
  }, {
    path: "/ja/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___ja"
  }, {
    path: "/ja/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___ja"
  }, {
    path: "/ko/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___ko"
  }, {
    path: "/ko/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___ko"
  }, {
    path: "/nl/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___nl"
  }, {
    path: "/nl/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___nl"
  }, {
    path: "/pl/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___pl"
  }, {
    path: "/pl/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___pl"
  }, {
    path: "/pt-br/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___pt-br"
  }, {
    path: "/pt-br/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___pt-br"
  }, {
    path: "/pt/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___pt"
  }, {
    path: "/pt/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___pt"
  }, {
    path: "/ru/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___ru"
  }, {
    path: "/ru/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___ru"
  }, {
    path: "/tr/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___tr"
  }, {
    path: "/tr/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___tr"
  }, {
    path: "/zh/destinations/:destination_id?/map",
    component: _2371b7e9,
    name: "destinations-destination_id-map___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/bookings",
    component: _1a2f459c,
    name: "itineraries-itinerary_id-bookings___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/edit",
    component: _07ae8528,
    name: "itineraries-itinerary_id-edit___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/products",
    component: _34e42274,
    name: "itineraries-itinerary_id-products___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/thank_you",
    component: _3aeabf58,
    name: "itineraries-itinerary_id-thank_you___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/travelers",
    component: _4bfdb5cc,
    name: "itineraries-itinerary_id-travelers___zh"
  }, {
    path: "/zh/spots/:spot_id?/experiences",
    component: _9bdebf68,
    name: "spots-spot_id-experiences___zh"
  }, {
    path: "/ca/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___ca"
  }, {
    path: "/de/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___de"
  }, {
    path: "/el/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___el"
  }, {
    path: "/en/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___en"
  }, {
    path: "/es/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___es"
  }, {
    path: "/fr/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___fr"
  }, {
    path: "/it/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___it"
  }, {
    path: "/ja/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___ja"
  }, {
    path: "/ko/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___ko"
  }, {
    path: "/nl/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___nl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___pl"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___pt-br"
  }, {
    path: "/pt/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___pt"
  }, {
    path: "/ru/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___ru"
  }, {
    path: "/tr/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___tr"
  }, {
    path: "/zh/itineraries/:itinerary_id?/groups/create",
    component: _0d637225,
    name: "itineraries-itinerary_id-groups-create___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/notes/create",
    component: _3e5ba528,
    name: "itineraries-itinerary_id-notes-create___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/overview/preview",
    component: _6280bcd8,
    name: "itineraries-itinerary_id-overview-preview___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/overview/shared",
    component: _1d5e826e,
    name: "itineraries-itinerary_id-overview-shared___zh"
  }, {
    path: "/ca/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___ca"
  }, {
    path: "/ca/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___ca"
  }, {
    path: "/ca/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___ca"
  }, {
    path: "/ca/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___ca"
  }, {
    path: "/de/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___de"
  }, {
    path: "/de/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___de"
  }, {
    path: "/de/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___de"
  }, {
    path: "/de/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___de"
  }, {
    path: "/el/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___el"
  }, {
    path: "/el/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___el"
  }, {
    path: "/el/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___el"
  }, {
    path: "/el/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___el"
  }, {
    path: "/en/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___en"
  }, {
    path: "/en/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___en"
  }, {
    path: "/en/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___en"
  }, {
    path: "/en/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___en"
  }, {
    path: "/es/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___es"
  }, {
    path: "/es/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___es"
  }, {
    path: "/es/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___es"
  }, {
    path: "/es/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___es"
  }, {
    path: "/fr/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___fr"
  }, {
    path: "/fr/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___fr"
  }, {
    path: "/fr/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___fr"
  }, {
    path: "/fr/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___fr"
  }, {
    path: "/it/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___it"
  }, {
    path: "/it/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___it"
  }, {
    path: "/it/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___it"
  }, {
    path: "/it/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___it"
  }, {
    path: "/ja/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___ja"
  }, {
    path: "/ja/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___ja"
  }, {
    path: "/ja/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___ja"
  }, {
    path: "/ja/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___ja"
  }, {
    path: "/ko/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___ko"
  }, {
    path: "/ko/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___ko"
  }, {
    path: "/ko/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___ko"
  }, {
    path: "/ko/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___ko"
  }, {
    path: "/nl/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___nl"
  }, {
    path: "/nl/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___nl"
  }, {
    path: "/nl/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___nl"
  }, {
    path: "/nl/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___nl"
  }, {
    path: "/pl/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___pl"
  }, {
    path: "/pl/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___pl"
  }, {
    path: "/pl/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___pl"
  }, {
    path: "/pl/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___pl"
  }, {
    path: "/pt-br/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___pt-br"
  }, {
    path: "/pt-br/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___pt-br"
  }, {
    path: "/pt-br/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___pt-br"
  }, {
    path: "/pt-br/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___pt-br"
  }, {
    path: "/pt/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___pt"
  }, {
    path: "/pt/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___pt"
  }, {
    path: "/pt/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___pt"
  }, {
    path: "/pt/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___pt"
  }, {
    path: "/ru/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___ru"
  }, {
    path: "/ru/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___ru"
  }, {
    path: "/ru/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___ru"
  }, {
    path: "/ru/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___ru"
  }, {
    path: "/tr/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___tr"
  }, {
    path: "/tr/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___tr"
  }, {
    path: "/tr/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___tr"
  }, {
    path: "/tr/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___tr"
  }, {
    path: "/zh/destinations/:destination_id?/guides/:guide_id",
    component: _75e08f6a,
    name: "destinations-destination_id-guides-guide_id___zh"
  }, {
    path: "/zh/destinations/:destination_id?/landmark/:landmark_id?",
    component: _1dfe7b87,
    name: "destinations-destination_id-landmark-landmark_id___zh"
  }, {
    path: "/zh/destinations/:destination_id?/spots/:spot_id",
    component: _d9634c46,
    name: "destinations-destination_id-spots-spot_id___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/destinations/:destination_id",
    component: _04be2f46,
    name: "itineraries-itinerary_id-destinations-destination_id___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/spots/:spot_id",
    component: _2a1ec608,
    name: "itineraries-itinerary_id-spots-spot_id___zh"
  }, {
    path: "/zh/profiles/:profile_id?/itineraries/:category?",
    component: _614f744e,
    name: "profiles-profile_id-itineraries-category___zh"
  }, {
    path: "/ca/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___ca"
  }, {
    path: "/de/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___de"
  }, {
    path: "/el/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___el"
  }, {
    path: "/en/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___en"
  }, {
    path: "/es/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___es"
  }, {
    path: "/fr/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___fr"
  }, {
    path: "/it/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___it"
  }, {
    path: "/ja/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___ja"
  }, {
    path: "/ko/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___ko"
  }, {
    path: "/nl/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___nl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___pl"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___pt-br"
  }, {
    path: "/pt/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___pt"
  }, {
    path: "/ru/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___ru"
  }, {
    path: "/tr/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___tr"
  }, {
    path: "/zh/itineraries/:itinerary_id?/destinations/:destination_id?/map",
    component: _5e14ddd0,
    name: "itineraries-itinerary_id-destinations-destination_id-map___zh"
  }, {
    path: "/ca/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___ca"
  }, {
    path: "/ca/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___ca"
  }, {
    path: "/de/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___de"
  }, {
    path: "/de/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___de"
  }, {
    path: "/el/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___el"
  }, {
    path: "/el/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___el"
  }, {
    path: "/en/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___en"
  }, {
    path: "/en/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___en"
  }, {
    path: "/es/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___es"
  }, {
    path: "/es/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___es"
  }, {
    path: "/fr/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___fr"
  }, {
    path: "/fr/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___fr"
  }, {
    path: "/it/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___it"
  }, {
    path: "/it/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___it"
  }, {
    path: "/ja/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___ja"
  }, {
    path: "/ja/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___ja"
  }, {
    path: "/ko/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___ko"
  }, {
    path: "/ko/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___ko"
  }, {
    path: "/nl/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___nl"
  }, {
    path: "/nl/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___nl"
  }, {
    path: "/pl/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___pl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___pl"
  }, {
    path: "/pt-br/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___pt-br"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___pt-br"
  }, {
    path: "/pt/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___pt"
  }, {
    path: "/pt/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___pt"
  }, {
    path: "/ru/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___ru"
  }, {
    path: "/ru/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___ru"
  }, {
    path: "/tr/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___tr"
  }, {
    path: "/tr/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___tr"
  }, {
    path: "/zh/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _18fce99e,
    name: "destinations-destination_id-guides-guide_id-spots-spot_id___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id",
    component: _3090e304,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/destinations/:destination_id?/landmark/:landmark_id?",
    component: _4ac095ae,
    name: "itineraries-itinerary_id-destinations-destination_id-landmark-landmark_id___zh"
  }, {
    path: "/zh/itineraries/:itinerary_id?/destinations/:destination_id?/spots/:spot_id",
    component: _085cded6,
    name: "itineraries-itinerary_id-destinations-destination_id-spots-spot_id___zh"
  }, {
    path: "/ca/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___ca"
  }, {
    path: "/de/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___de"
  }, {
    path: "/el/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___el"
  }, {
    path: "/en/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___en"
  }, {
    path: "/es/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___es"
  }, {
    path: "/fr/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___fr"
  }, {
    path: "/it/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___it"
  }, {
    path: "/ja/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___ja"
  }, {
    path: "/ko/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___ko"
  }, {
    path: "/nl/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___nl"
  }, {
    path: "/pl/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___pl"
  }, {
    path: "/pt-br/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___pt-br"
  }, {
    path: "/pt/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___pt"
  }, {
    path: "/ru/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___ru"
  }, {
    path: "/tr/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___tr"
  }, {
    path: "/zh/itineraries/:itinerary_id?/destinations/:destination_id?/guides/:guide_id?/spots/:spot_id",
    component: _329f1150,
    name: "itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
