import { Locale } from '~/models/Locale'

export abstract class Translation {
	protected abstract translate(key: string, locale: Locale): string
}

export class TranslationManager {
	static translate(key: string, locale: Locale): string {
		try {
			// eslint-disable-next-line @typescript-eslint/no-require-imports
			const service = require('~/services/Translation')
			return service.TranslationService.getInstance().translate(key, locale)
		} catch {
			console.warn(`Unable to translate ${key}. Translation service needs to be implemented`)
			return key
		}
	}
}
