












































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Dialog from '~/components/UI/Dialog.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import CardItinerary from '~/components/context/items/itineraries/CardItinerary.vue'
import MosaicImages from '~/components/PassporterUI/MosaicImages.vue'
import Container from '~/components/PassporterUI/Container.vue'
import IconPlus from '~/components/UI/Icons/IconPlus.vue'
import InputText from '~/components/PassporterUI/InputText.vue'

export default Vue.extend({
	name: 'ItineraryDialog',
	components: {
		InputText,
		IconPlus,
		Container,
		MosaicImages,
		CardItinerary,
		Dialog,
		PButton,
	},
	props: {
		itineraryId: {
			type: String,
			default: '',
		},
		imagesUrl: {
			type: Array,
			required: true,
		},
		initialStepTitle: {
			type: String,
			required: true,
		},
		initialStepSubtitle: {
			type: String,
			required: true,
		},
		intermediateStepTitle: {
			type: String,
			required: true,
		},
		intermediateStepSubtitle: {
			type: String,
			required: true,
		},
		isSaved: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			step: undefined as undefined | number,
			showDialog: false,
			firstStep: 0,
			secondStep: 1,
			lastStep: 2,
		}
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			screenSize: 'screenSize',
			ownItineraries: 'profile/ownItineraries',
			moreOwnItineraries: 'profile/moreOwnItineraries',
			destination: 'destination/destination',
		}),
		itineraryIdContext(): string {
			return this.$route.params.itinerary_id
		},
	},
	mounted() {
		if (this.isAuthenticated && !this.ownItineraries) {
			this.obtainUserItineraries()
		}
	},
	methods: {
		open() {
			if (this.itineraryIdContext) {
				this.$emit('choosedItinerary', this.itineraryIdContext)
				this.continueNextStep()
			} else if (!this.ownItineraries?.length) {
				this.step = this.firstStep
			} else {
				this.step = this.secondStep
			}

			this.showDialog = true
		},
		cancel() {
			this.showDialog = false
		},
		goToStep(step: number) {
			this.step = step
		},
		seeItinerary() {
			this.$emit('seeItinerary')
			this.redirectoToItineraryMainView()
			this.showDialog = false
		},
		redirectoToItineraryMainView() {
			const routeToMap = this.$navigation.itinerary.getItinerary(this.itineraryId)
			this.$router.push(routeToMap)
		},
		async obtainUserItineraries() {
			await this.$store.dispatch('profile/getOwnItineraries')
		},
		loadMoreItineraries() {
			this.$store.dispatch('profile/getMoreOwnItineraries')
		},
		continueExploring() {
			this.$emit('continueExploring')
			this.showDialog = false
		},
		continueNextStep() {
			this.$emit('clickContinue')
		},
	},
})
