














































import Vue from 'vue'
import { mapGetters } from 'vuex'
import ProfileForm from '~/components/context/profile/ProfileForm.vue'
import ProfileTripsView from '~/components/context/profile/ProfileTripsView.vue'
import Wizard from '~/components/PassporterUI/Wizard.vue'
import { Profile } from '~/models/Profile'
import { ProfileService } from '~/passporter-services/profile/service'

export default Vue.extend({
	name: 'ProfileWizard',
	components: { Wizard, ProfileTripsView, ProfileForm },
	data() {
		const whishlist: Record<string, string> = {}
		const visitedList: Record<string, string> = {}
		const isOpen: boolean = false
		const currentStep: number = 0
		const form: Profile = {}
		const wishlistStickers: string[] = [
			require('~/assets/logos/profie-whishlist-sticker-passporter.svg'),
			require('~/assets/logos/profie-whishlist-sticker-island.svg'),
			require('~/assets/logos/profie-whishlist-sticker-desert.svg'),
			require('~/assets/logos/profie-whishlist-sticker-village.svg'),
		]
		const visitedStickers: string[] = [
			require('~/assets/logos/profie-visited-sticker-traveler.svg'),
			require('~/assets/logos/profie-visited-sticker-worldwide.svg'),
			require('~/assets/logos/profie-visited-sticker-passporter.svg'),
			require('~/assets/logos/profie-visited-sticker-desert.svg'),
		]
		const assetsByStep = [
			{
				title: 'wishlistSetup_heading',
				description: 'wishlistSetup_subheading',
				banner: require('~/assets/profile-wizard-wishlist.png'),
			},
			{
				title: 'pastAdventuresSetup_heading',
				description: 'pastAdventuresSetup_subheading',
				banner: require('~/assets/profile-wizard-visited.png'),
			},
			{
				title: 'profileCreationSetup_heading',
				description: 'profileCreationSetup_subheading',
				banner: require('~/assets/profile-wizard-form.png'),
			},
		]
		const formErrors: Partial<Record<keyof Profile, string>> = {}
		return {
			isOpen,
			wishlistStickers,
			visitedStickers,
			whishlist,
			visitedList,
			assetsByStep,
			currentStep,
			form,
			formErrors,
		}
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			screenSize: 'screenSize',
		}),
		disableContinue(): boolean {
			let isDisabled = false
			if (this.currentStep === 0) isDisabled = Object.keys(this.whishlist).length === 0
			if (this.currentStep === 1) isDisabled = Object.keys(this.visitedList).length === 0
			return isDisabled
		},
	},
	mounted() {
		if (this.user) {
			this.form.name = this.user.name
			this.form.username = this.user.username
		}
	},
	methods: {
		open() {
			this.isOpen = true
		},
		close() {
			this.isOpen = false
		},
		setWishList(travelList: Record<string, string>) {
			this.whishlist = travelList
		},
		setVisitedList(travelList: Record<string, string>) {
			this.visitedList = travelList
		},
		changeStepData(newStep: number) {
			this.currentStep = newStep
		},
		async submitData(): Promise<void> {
			const hasErrors = await this.validateProfile()
			if (hasErrors) return
			try {
				const updatedProfile = await ProfileService.updateProfile(this.user.id, this.form)
				await ProfileService.addWishlist(this.user.id, Object.keys(this.whishlist))
				await ProfileService.addVisitedList(this.user.id, Object.keys(this.visitedList))
				this.form.id = updatedProfile.id
				await this.$store.dispatch('auth/setProfile', this.form)
				await this.$store.dispatch('alerts/setSuccess', {
					text: this.$t('Profile_edit_update_profile_success'),
				})
				this.close()
			} catch {
				await this.$store.dispatch('alerts/setError', this.$t('Profile_edit_update_profile_error'))
			}
		},
		handleProfile(profile: Profile): void {
			Object.keys(profile).forEach((key) => {
				const theKey = key as keyof Profile
				if (profile[theKey] !== this.form[theKey]) {
					this.formErrors[theKey] = ''
				}
			})
			this.form = { ...profile }
		},
		async validateProfile(): Promise<Partial<Record<keyof Profile, string>> | undefined> {
			const errors = await ProfileService.validateProfile(this.form, this.user)
			if (errors) this.formErrors = errors
			return errors
		},
	},
})
