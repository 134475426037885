import { SpotsRequest } from './apiTypes'
import { Spot2 as Spot } from '~/models/Spot'
import { GuideEntityMapper } from '~/passporter-services/guide/entityMapper'
import { SpotEntityMapper } from '~/passporter-services/spot/entityMapper'
import { Guide2 as Guide } from '~/models/Guide'
import { Category } from '~/models/Category'
import { GuideRepository } from './repository'

export default class GuideService {
	repository: any
	constructor(repository?: any) {
		this.repository = repository
	}

	async getGuide(id: string): Promise<Guide> {
		const res = await this.repository.getItinerary({
			itineraryId: id,
		})
		const { data } = res
		return GuideEntityMapper.toGuide(data)
	}

	static async getCategories(siteId: string): Promise<Category[]> {
		return await GuideRepository.getCategories(siteId)
	}

	static async getByCategory(siteId: string, categoryId?: string): Promise<Guide[]> {
		return await GuideRepository.getGuideBlocksByCategory(siteId, categoryId)
	}

	async getSpots(
		guideId: string,
		page?: number
	): Promise<{
		count: number
		next: number
		results: Spot[]
		pageSize: number
	}> {
		const pageSize: number = 10
		const requestData: SpotsRequest = {
			itineraryId: guideId,
			page,
			pageSize,
		}
		const res = await this.repository.getItinerarySpots(requestData)
		const { data } = res
		const { count, next, results } = data
		const parsedResults: Spot[] = SpotEntityMapper.toItinerarySpots(results)
		return { count, next, results: parsedResults, pageSize }
	}
}
