import { Page } from '~/models/PaginatedItems'

export type ApiPage<T> = {
	results: T[]
	count?: number
	next?: number
	prev?: number
	page?: number
	page_size?: number
}

export function toPage<ApiT, T>(apiPage: ApiPage<ApiT>, mappedItems: T[]): Page<T> {
	return {
		items: mappedItems,
		count: apiPage.count,
		prev: apiPage.prev,
		next: apiPage.next,
	}
}
