





















import Vue from 'vue'
import { mapGetters } from 'vuex'
import InputDateTime from '~/components/UI/InputDateTime.vue'
import Calendar from '~/passporter-services/shared/calendar'

export default Vue.extend({
	name: 'ItinerarySideNavDatePicker',
	components: { InputDateTime },
	data() {
		return {
			date: undefined,
		}
	},
	computed: {
		...mapGetters({
			itinerary: 'itinerary/itinerary',
			screenSize: 'screenSize',
		}),
	},
	methods: {
		formatDate(date: string) {
			if (!date) return null
			const formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
				day: '2-digit',
				month: 'short',
			})
			return formatter.format(Calendar.getDateUTC(date))
		},
		inputDate(dates: string[]) {
			this.$store.dispatch('itinerary/editDate', {
				startDate: dates[0],
				endDate: dates[1],
			})
		},
		open() {
			const input = this.$refs.input as any
			input?.open()
		},
	},
})
