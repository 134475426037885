export type RequestParams = {
	pageSize?: number
	page?: number
	q?: number | string
	hasOwner?: boolean
	type?: 'destination' | 'spot' | 'guide' | 'itinerary' | 'traveler' | 'product'
}

export default class ParamsBuilder {
	private params: URLSearchParams

	constructor() {
		this.params = new URLSearchParams()
	}

	public obtainParams(params: RequestParams): string {
		this.addDefaultParams()

		if (params.pageSize) {
			this.params.append('page_size', params.pageSize.toString())
		}
		if (params.page) {
			this.params.append('page', params.page.toString())
		}
		if (params.q !== undefined) {
			this.params.append('q', params.q.toString())
		}

		if (params.hasOwner !== undefined) {
			this.params.append('has_owner', params.hasOwner.toString())
		}

		if (params.type !== undefined) {
			this.params.append('type', params.type.toString())
		}
		return this.params.toString()
	}

	private addDefaultParams(): void {
		this.params.append('page_size', '10')
		this.params.append('page', '0')
	}
}
