















































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Row from '~/components/UI/Row.vue'
import Col from '~/components/UI/Col.vue'
import PIconClose from '~/components/PassporterUI/Icon/PIconClose.vue'
import PIconChevron from '~/components/PassporterUI/Icon/PIconChevron.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import Scrollbar from '~/components/PassporterUI/Scrollbar.vue'
import POverlay from '~/components/PassporterUI/POverlay.vue'

export type DialogType = Vue & {
	open: (args?: any) => void
	close: () => void
	goToStep: (step: number) => void
}

export default Vue.extend({
	name: 'Dialog',
	components: {
		Scrollbar,
		POverlay,
		PButton,
		PIconChevron,
		PIconClose,
		Col,
		Row,
	},
	props: {
		value: {
			type: Boolean,
			default: null,
		},
		title: {
			type: String,
			default: undefined,
		},
		subtitle: {
			type: String,
			default: undefined,
		},
		persistent: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
		showCloseButton: {
			type: Boolean,
			default: undefined,
		},
		step: {
			type: Number,
			default: undefined,
		},
		fullscreen: {
			type: Boolean,
			default: false,
		},
		semifullscreen: {
			type: Boolean,
			default: false,
		},
		width: {
			type: Number,
			default: undefined,
		},
		maxWidth: {
			type: Number,
			default: 500,
		},
		contentClass: {
			type: String,
			default: '',
		},
		contentStyles: {
			type: Object,
			default: undefined,
		},
		headerStyles: {
			type: Object,
			default: undefined,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		lines: {
			type: Boolean,
			default: false,
		},
		allowOverflow: {
			type: Boolean,
			default: false,
		},
		titleClose: {
			type: Boolean,
			default: false,
		},
		level: {
			type: Number,
			default: 1,
		},
		noPadding: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			localShow: false,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		show: {
			get(): boolean {
				if (this.value !== null) {
					return this.value
				} else {
					return this.localShow
				}
			},
			set(newValue: boolean): void {
				;(this.localShow as boolean) = newValue
				this.$emit('input', newValue)
			},
		},
		localStep: {
			get(): number {
				return this.step
			},
			set(newValue: number): void {
				this.$emit('update:step', newValue)
			},
		},
		dialogClass(): string {
			const classes = `${this.allowOverflow ? 'overflow-y-visible ' : ''}`
			if (this.fullscreen) {
				return classes + 'fullscreen'
			}
			if (this.semifullscreen) {
				return classes + 'fixed mb-0 bottom-0 rounded-t-4 semifullscreen'
			}
			if (this.screenSize.xs) {
				return classes + 'rounded-6 max-height-99-per small'
			} else {
				return classes + 'rounded-6 max-height-90-per small'
			}
		},
		isPersistent(): boolean {
			return !!(this.persistent || (this.$listeners && (this.$listeners as any).close))
		},
		styles(): {} {
			if (this.allowOverflow) {
				if (this.contentStyles) {
					return {
						...this.contentStyles,
						overflowY: 'visible !important',
					}
				}
				return { overflowY: 'visible !important' }
			}
			return this.contentStyles ?? {}
		},
		closable(): boolean {
			return !this.backable && (this.showCloseButton ?? (this.small || this.fullscreen))
		},
		backable(): boolean {
			return this.step > 0 || this.level > 1
		},
		showHeader(): boolean {
			return Boolean(
				this.title ||
					this.$slots.title ||
					this.$slots.header ||
					this.small ||
					this.closable ||
					this.backable
			)
		},
		showTitle(): boolean {
			return Boolean(this.$slots.header || this.title || this.$slots.title)
		},
		showSubtitle(): boolean {
			return Boolean(this.subtitle || this.$slots.subtitle)
		},
		showActions(): boolean {
			return Boolean(this.$slots.actions)
		},
	},
	methods: {
		open() {
			this.show = true
		},
		close() {
			if (!this.isPersistent) {
				if (this.$listeners.close) {
					this.$emit('close', true)
				} else {
					this.show = false
					this.$emit('closed', true)
				}
			}
		},
		clickOutside() {
			if (!this.isPersistent) {
				if (this.$listeners.close) {
					this.$emit('close', false)
				} else {
					this.show = false
					this.$emit('closed', false)
				}
			}
		},
		goBack() {
			if (this.localStep) {
				this.localStep = this.localStep - 1
			} else if (this.backable) {
				this.close()
			}
		},
	},
})
