


















































import Vue from 'vue'
import { PropOptions } from 'vue/types/umd'
import GoogleBrowser from '~/components/context/shared/browser/GoogleBrowser.vue'
import Chip from '~/components/PassporterUI/Chip.vue'
import { Destination2 } from '~/models/Destination'
import { Spot2 } from '~/models/Spot'
import PIconClose from '~/components/PassporterUI/Icon/PIconClose.vue'

export default Vue.extend({
	name: 'ProfileTripsView',
	components: { Chip, GoogleBrowser, PIconClose },
	props: {
		travelList: {
			type: Object,
			default: undefined,
		} as PropOptions<Record<string, string>>,
		screenSize: {
			type: Object,
			required: true,
		},
		stickers: {
			type: Array,
			default: () => [],
		} as PropOptions<string[]>,
		colorPalette: {
			type: String,
			default: 'primary',
		},
		placeholder: {
			type: String,
			default: 'form_selectaDestination_placeholder',
		},
		paddingHeader: {
			type: String,
			default: 'pt-12',
		},
		chipsContainerHeight: {
			type: Number,
			default: 140,
		},
		topOffset: {
			type: Number,
			default: 0,
		},
	},
	data() {
		const selectedPlaces: Record<string, string> = {}
		return { selectedPlaces }
	},

	methods: {
		selectPlace(place: Destination2 | Spot2) {
			if (!place?.id) {
				return this.$store.dispatch('alerts/SetError', this.$t('error_destination_not_found'))
			}
			const selectedPlaces = { ...this.travelList, [place.id]: place.name }
			this.$emit('updateDestinations', selectedPlaces)
		},

		removePlace(placeId: string) {
			const selectedPlaces = { ...this.travelList }
			delete selectedPlaces[placeId]
			this.$emit('updateDestinations', selectedPlaces)
		},
	},
})
