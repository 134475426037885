import VueI18n, { LocaleMessages } from 'vue-i18n'
import { DEFAULT_LOCALE, Locale } from '~/models/Locale'
import { Translation } from '~/passporter-services/shared/translation'

export class TranslationService extends Translation {
	private static instance: TranslationService
	private readonly i18n = new VueI18n({
		locale: DEFAULT_LOCALE,
		fallbackLocale: DEFAULT_LOCALE,
		messages: this.getTranslations(),
	})

	public static getInstance(): TranslationService {
		if (!this.instance) this.instance = new TranslationService()
		return this.instance
	}

	translate(key: string, locale: Locale): string {
		this.setLanguage(locale)
		if (!this.hasTranslation(key)) this.setLanguage(DEFAULT_LOCALE)

		let translation = key
		if (this.hasTranslation(key)) translation = this.doTranslate(key)
		return translation
	}

	private hasTranslation(key: string): boolean {
		return this.i18n.te(key)
	}

	private setLanguage(locale: Locale): void {
		this.i18n.locale = locale
	}

	private doTranslate(key: string): string {
		return this.i18n.t(key) as string
	}

	private getTranslations(): LocaleMessages {
		const translations: LocaleMessages = {}
		const languageFiles = require.context(
			'../locales',
			true,
			/.\/([a-z]{2}(-[A-Z]{2})?)\/translations.json/i
		)
		const languageFilePaths = languageFiles.keys()

		languageFilePaths.forEach((filePath: string) => {
			const languageKey = filePath.split('/')[1]
			const langJson: Record<string, string> = languageFiles(filePath)
			translations[languageKey] = langJson
		})

		return translations
	}
}
