




























































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import PIconMenuArrow from '~/components/PassporterUI/Icon/PIconMenuArrow.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import ItineraryGoBackButton from '~/components/context/items/itineraries/ItineraryGoBackButton.vue'
import ItinerarySideNavDatePicker from '~/components/context/items/itineraries/ItinerarySideNavDatePicker.vue'
import ItinerarySideNavItem from '~/components/context/items/itineraries/ItinerarySideNavItem.vue'
import TravelersDialog, {
	TravelersDialogType,
} from '~/components/context/items/itineraries/TravelersDialog.vue'
import PIconMenu from '~/components/PassporterUI/Icon/PIconMenu.vue'
import TravelersCount from '~/components/context/items/itineraries/tripOverview/TravelersCount.vue'

export default Vue.extend({
	name: 'ItinerarySideNav',

	components: {
		TravelersCount,
		PIconMenuArrow,
		PButton,
		ItinerarySideNavItem,
		ItineraryGoBackButton,
		ItinerarySideNavDatePicker,
		TravelersDialog,
		PIconMenu,
	},
	props: {
		isMobile: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		const itineraryId: string = ''
		const collapse: boolean = true
		return {
			itineraryId,
			collapse,
		}
	},

	computed: {
		...mapGetters({
			itinerary: 'itinerary/itinerary',
			destination: 'itinerary/destination',
		}),
		travelersAvatars(): string[] | undefined {
			if (!this.itinerary.travelers || !this.itinerary.travelersPictures?.length) return []
			return this.itinerary.travelersPictures
		},
		amounOfTravelers(): number {
			if (!this.itinerary.numTravelers) return 0
			return this.itinerary.numTravelers
		},
	},
	mounted() {
		this.itineraryId = this.$route.params.itinerary_id as string
	},
	methods: {
		async goHome(): Promise<void> {
			const homeRoute = this.$navigation.home.getHomeRoute()
			await this.$router.replace(homeRoute)
		},
		goToPreview() {
			if (!this.itineraryId) return
			this.handleRedirection(this.$navigation.itinerary.getOverviewPreview)
		},
		goToitineraryView() {
			if (!this.itineraryId) return
			this.handleRedirection(this.$navigation.itinerary.getItinerary)
		},
		goToNotesView() {
			if (!this.itineraryId) return
			this.handleRedirection(this.$navigation.itinerary.getNotesList)
		},
		openInviteFriendsDialog() {
			const TravelersDialog: TravelersDialogType = this.$refs.travelersDialog as TravelersDialogType
			TravelersDialog?.open()
		},
		goTripDialogToEdit() {
			this.$nuxt.$emit('open-itineraryEdit', this.itinerary)
		},
		handleRedirection(callback: (id: string) => {}) {
			const currentRoute = this.$route.fullPath
			const redirectionRoute = callback(this.itineraryId)
			if (currentRoute === redirectionRoute) return (this.collapse = true)
			this.$router.replace(redirectionRoute)
			this.collapse = true
		},
		openMenu() {
			this.collapse = false
			this.sendViewEvent()
		},
		sendViewEvent(): void {
			const context = this.$tracker.getContext(this.$route?.name)
			const parameters: Record<string, string> = {
				element: 'menu',
				platform: 'web',
				client: 'passporter',
				id: this.itinerary.id,
				destination: this.destination.name.toLowerCase(),
			}
			if (context) parameters.context = context
			this.$tracker.event('view', parameters)
		},
	},
})
