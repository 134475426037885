










import Vue from 'vue'
import OkIcon from '~/components/context/items/itineraries/configuration/OkIcon.vue'

export default Vue.extend({
	name: 'FieldStatusIcon',
	components: {
		OkIcon,
	},
	props: {
		completed: {
			type: Boolean,
			default: false,
		},
	},
})
