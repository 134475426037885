













































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import ItineraryAdministratorForm from '~/components/context/items/itineraries/configuration/ItineraryAdministratorForm.vue'
import ItineraryLoader from '~/components/context/items/itineraries/ItineraryLoader.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import DeleteDialog from '~/components/UI/DeleteDialog.vue'
import Dialog from '~/components/UI/Dialog.vue'
import Itinerary, { ItineraryForm } from '~/models/Itinerary'
import ProfileItineraryForm from '~/components/context/items/itineraries/configuration/ProfileItineraryForm.vue'

const initForm = (itinerary?: Itinerary): ItineraryForm => ({
	id: itinerary?.id || null,
	name: itinerary?.name,
	startDate: itinerary?.startDate,
	endDate: itinerary?.endDate,
	isPrivate: itinerary?.isPrivate === undefined || itinerary?.isPrivate,
	coverId: undefined as string | undefined,
	showDate: true,
	destination: undefined,
	numDays: undefined,
	categoryIds: undefined as string[] | undefined,
	origin: undefined,
})

export default Vue.extend({
	name: 'ItineraryAdministratorDialog',
	components: {
		Dialog,
		PButton,
		DeleteDialog,
		ItineraryLoader,
		ItineraryAdministratorForm,
		ProfileItineraryForm,
	},
	data() {
		return {
			form: initForm(),
			callback: undefined as Function | undefined,
			nameApiError: undefined as string | undefined,
			startDateApiError: undefined as string | undefined,
			modified: false,
			localShow: false,
			showConfirmDialog: false,
			validatedForm: false,
			loader: false,
			isRemovable: false,
			step: 0,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
			categories: 'itinerary/categories',
			pendingMedias: 'media/pendingMedias',
			itinerary: 'itinerary/itinerary',
		}),
		calculateProgressPercent(): number {
			let totalFields = 0
			let completedFields = 0
			if (this.form.id) {
				totalFields = 4
				if (this.form.name) {
					completedFields++
				}
				if (this.form.coverId || this.pendingMedias[0]) {
					completedFields++
				}
				if (this.form.destination) {
					completedFields++
				}
				if (this.hasDates() || this.form.numDays) {
					completedFields++
				}
			} else {
				totalFields = 3
				if (this.form.name) {
					completedFields++
				}
				if (this.form.destination) {
					completedFields++
				}
				if (this.hasDates() || this.form.numDays) {
					completedFields++
				}
			}

			const result = (completedFields * 100) / totalFields
			const roundedResult = Math.round(result)
			return roundedResult
		},
		destination(): string | undefined {
			let result
			if (this.form.destination) {
				result = this.form.destination.name
			}
			return result
		},
		urlCover(): string | undefined {
			return this.form.destination?.cover
		},
		showEditCover(): Boolean {
			return Boolean(this.form.id)
		},
		hasErrors(): boolean {
			return Boolean(
				(this.form.id && !this.form.name) ||
					!this.form.destination ||
					this.nameApiError ||
					this.startDateApiError ||
					this.loader
			)
		},
		showMainDialog: {
			get(): boolean {
				let result = this.localShow
				if (this.value) {
					result = this.value
				}
				return result
			},
			set(newValue: boolean): void {
				this.localShow = newValue
				this.$emit('input', newValue)
			},
		} as any,
		defineTitle(): string {
			let result = this.$t('nav_general_plantrip')
			if (this.form.id) {
				result = this.$t('B2C_itinerarymap_edittravel')
			}
			if (this.loader) {
				result = ''
			}
			return result as string
		},
		isEditionMode(): boolean {
			return Boolean(this.form.id)
		},
		handleDisabled(): boolean {
			const hasNotTime = this.hasInvalidOrMissingDates() && Boolean(!this.form.numDays)
			const hasDestination = Boolean(this.form.destination)
			const hasName = Boolean(this.form.name)
			return hasNotTime || !hasDestination || !hasName
		},
	},
	watch: {
		show(show) {
			if (show) {
				this.$mixpanel?.track('Create Itinerary impression')
			}
		},
	},
	methods: {
		open(itinerary?: Itinerary, origin?: string, callback: Function | undefined = undefined) {
			this.callback = callback
			this.refresh()
			if (itinerary) {
				this.isRemovable = itinerary.isRemovable || false
				this.form = initForm(itinerary)
				if (itinerary.destinations) {
					this.form.destination = itinerary.destinations[0]
				}
			}
			if (!this.form.id && origin) {
				this.form.origin = origin
			}
			this.showMainDialog = true
		},
		refresh() {
			const destinationSearcher = this.$refs.destinationSearcher as any
			if (destinationSearcher) {
				destinationSearcher.clear()
			}
			this.clearApiErrors()
			this.form = initForm()
			this.modified = false
			this.loader = false
			this.isRemovable = false
		},
		exitWithoutSaveChanges() {
			if (this.callback) {
				this.callback()
			}
			this.closeMainDialog()
		},
		keepEditing(): void {
			this.showMainDialog = true
		},
		closeMainDialog() {
			this.showMainDialog = false
		},
		cancelEdition() {
			if (this.modified) return this.keepEditing()
			this.closeMainDialog()
		},
		async manageCover() {
			if (!this.pendingMedias[0]) return
			const cover = this.pendingMedias[0].url
			await this.$store.dispatch('itinerary/setItineraryCover', cover)
			this.$store.dispatch('media/clearPendingMedias')
		},

		async saveEditionChanges() {
			this.loader = true
			this.prepareDatesForSubmission()

			try {
				this.handlePendingMedias()
				const editionForm = { ...this.form }
				await this.$store.dispatch('itinerary/edit', editionForm)
				await this.showSuccessMessage()
				await this.manageCover()
				this.closeMainDialog()
			} catch (error: any) {
				if (error.formErrors) {
					this.manageErrors(error.formErrors)
				}
			} finally {
				this.loader = false
			}
		},

		async createItinerary() {
			this.addTracker()
			this.loader = true
			this.prepareDatesForSubmission()

			try {
				const itineraryCreated = await this.doCreation()
				this.$nuxt.$emit('itinerary-created')
				this.closeMainDialog()

				if (this.callback && itineraryCreated) {
					this.callback(itineraryCreated)
				} else {
					this.$store.dispatch('itinerary/openItinerary', {
						itinerary: itineraryCreated,
					})
				}
			} catch (error: any) {
				if (error.formErrors) {
					this.manageErrors(error.formErrors)
				}
			} finally {
				this.loader = false
				this.addCreateTracker()
			}
		},
		async showSuccessMessage() {
			await this.$store.dispatch('alerts/setSuccess', {
				text: this.$t('Toast_Itinerary_Savedchanges'),
			})
		},
		async doCreation(): Promise<Itinerary | undefined> {
			const creationForm = { ...this.form }
			return await this.$store.dispatch('itinerary/create', creationForm)
		},
		handlePendingMedias() {
			if (this.pendingMedias[0]) {
				this.form.coverId = this.pendingMedias[0].id
			}
		},
		prepareDatesForSubmission() {
			if (!this.form.showDate) {
				this.form.startDate = this.form.id ? null : undefined
				this.form.endDate = this.form.id ? null : undefined
			}
		},
		hasInvalidOrMissingDates() {
			return this.invalidTripDates() || this.missingOneDate()
		},
		hasDates(): boolean {
			return Boolean(this.form?.startDate && this.form?.endDate)
		},
		invalidTripDates(): boolean {
			return this.hasDates() && this.form.endDate! < this.form.startDate!
		},
		missingOneDate(): boolean {
			const hasOnlyStartDate = this.form?.startDate && !this.form?.endDate
			const hasOnlyEndDate = !this.form?.startDate && this.form?.endDate
			return Boolean(hasOnlyStartDate || hasOnlyEndDate)
		},
		manageErrors(errors: any): void {
			this.nameApiError = errors.name
			this.startDateApiError = errors.startDate
		},
		clearApiErrors() {
			this.nameApiError = undefined
			this.startDateApiError = undefined
		},
		addTracker() {
			this.$mixpanel?.track('Click button create itinerary', {
				count_categories_selected: this.form.categoryIds?.length,
			})
		},
		addCreateTracker() {
			const referrer = this.$tracker.getContext(this.$route?.name)
			if (!referrer) return
			const parameters = {
				action: 'create trip',
				platform: 'web',
				element: 'trip',
				destination: this.form.destination?.name ?? '',
				id: this.form.destination?.id ?? '',
				referrer,
			}
			this.$tracker.event('create', parameters)
		},
	},
})
