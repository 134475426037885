






















import Vue from 'vue'
import { mapGetters } from 'vuex'
import Calendar from '~/passporter-services/shared/calendar'
import CircleProgressBar from '~/components/context/items/itineraries/configuration/CircleProgressBar.vue'
import Cover from '~/components/PassporterUI/Cover.vue'

export default Vue.extend({
	name: 'ProfileItineraryForm',
	components: { Cover, CircleProgressBar },
	props: {
		urlCover: {
			type: String,
			default: undefined,
		},
		percent: {
			type: Number,
			default: 0,
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			calendar: new Calendar(this.$i18n.locale),
		}
	},
	computed: {
		...mapGetters({
			theme: 'theme',
		}),
		formatedPercentage(): string {
			return `${this.percent}%`
		},
	},
})
