enum AnalyticsEvent {
	ADD = 'add',
	VIEW = 'view',
	CLICK = 'click',
	CREATE = 'create',
	DOWNLOAD = 'download',
	REGISTER = 'register',
	SEARCH = 'search',
	SHARE = 'share',
	EDIT = 'edit',
	DELETE = 'delete',
}

const ANALYTICS_PLATFORM = 'web'

type AnalyticsEventParams = {
	platform: typeof ANALYTICS_PLATFORM
	client?: string
	context?: string
	element?: string
	title?: string
	id?: string
	destination?: string
	scope?: string
	type?: string
	referer?: string
	query?: string
	channel?: string
	action?: string
}

export { AnalyticsEvent, ANALYTICS_PLATFORM }
export type { AnalyticsEventParams }
