export type HttpClientResponse<T> = {
	status: number
	data?: T
}

abstract class BaseHttpClient {
	abstract get<T>(
		url: string,
		params?: Record<string, any>,
		headers?: Record<string, string>
	): Promise<HttpClientResponse<T>>

	abstract post<T>(
		url: string,
		body: any,
		params?: Record<string, any>,
		headers?: Record<string, string>
	): Promise<HttpClientResponse<T>>

	abstract patch<T>(
		url: string,
		body: any,
		params?: Record<string, any>,
		headers?: Record<string, string>
	): Promise<HttpClientResponse<T>>

	abstract delete<T>(url: string, headers?: Record<string, string>): Promise<HttpClientResponse<T>>
}

class BaseRepository {
	protected static async get<T>(
		url: string,
		params?: Record<string, any>,
		headers?: Record<string, string>
	): Promise<T> {
		const response = await this.getHttpClient().get<T>(url, params, headers)
		return this.manageResponse(response)!
	}

	protected static async post<T>(
		url: string,
		body?: any,
		params?: Record<string, any>,
		headers?: Record<string, string>
	): Promise<T | undefined> {
		const response = await this.getHttpClient().post<T>(url, body, params, headers)
		return this.manageResponse(response)
	}

	protected static async patch<T>(
		url: string,
		body: any,
		params?: Record<string, any>,
		headers?: Record<string, string>
	): Promise<T | undefined> {
		const response = await this.getHttpClient().patch<T>(url, body, params, headers)
		return this.manageResponse(response)
	}

	protected static async delete<T>(
		url: string,
		headers?: Record<string, string>
	): Promise<T | undefined> {
		const response = await this.getHttpClient().delete<T>(url, headers)
		return this.manageResponse(response)
	}

	private static manageResponse<T>(response: HttpClientResponse<T>) {
		if (response.status >= 400) return Promise.reject(response)
		if (response.status === 204 || !response.data) return
		return response.data
	}

	private static getHttpClient(): BaseHttpClient {
		try {
			// eslint-disable-next-line @typescript-eslint/no-require-imports
			const file = require('~/infrastructure/httpClient')
			return file.HttpClient.getInstance()
		} catch (e) {
			console.error(e)
			throw new Error('Http Client not implemented')
		}
	}
}

export { BaseHttpClient, BaseRepository }
