












import Vue from 'vue'
import Alerts from '~/components/context/core/Alerts.vue'
import RootDialogs from '~/components/context/core/RootDialogs.vue'
import GoogleOneTapSignIn from '~/components/context/auth/oauth/GoogleOneTapSignIn.vue'
import Col from '~/components/UI/Col.vue'
import StoreInit from '~/components/context/core/StoreInit.vue'

export default Vue.extend({
	components: {
		StoreInit,
		Col,
		GoogleOneTapSignIn,
		RootDialogs,
		Alerts,
	},
	middleware({ store }) {
		store.dispatch('goToScrollPosition', 0)
	},
	data: () => ({
		loaded: false,
	}),

	head() {
		return {
			htmlAttrs: {
				lang: this.$i18n.locale,
			},
		}
	},
	mounted() {
		this.loaded = true
	},
})
