import { Block2, BlockContentType } from '~/models/Block'
import { LinkEntityMapper } from '~/passporter-services/link/entityMapper'
import { ProfileEntityMapper } from '~/passporter-services/profile/entityMapper'
import { DestinationMapperEntity } from '~/passporter-services/destination/toEntity'
import { SpotEntityMapper } from '~/passporter-services/spot/entityMapper'
import { ItineraryEntityMapper } from '~/passporter-services/itinerary/entityMapper'
import { SiteEntityMapper } from '~/passporter-services/site/entityMapper'
import { ApiBlockType } from '~/passporter-services/block/apiTypes'

export class BlockEntityMapper {
	static toBlocks = (blocks: any[]) => {
		return blocks.map((block) => this.toBlock(block))
	}

	static toBlock = (block: any): Block2<BlockContentType> => {
		return {
			id: block.id,
			subtitle: block.subtitle,
			title: block.title,
			header: block.header,
			description: block.description,
			image: block.image,
			type: block.type,
			button: block.button,
			link: block.link,
			content: this.toBlockContent(block.type, block.content),
		}
	}

	static toBlockContent = (type: ApiBlockType, content: BlockContentType[]) => {
		const parsers: Record<ApiBlockType, (content: any) => BlockContentType[]> = {
			itinerary: (content: any) => ItineraryEntityMapper.toItineraries(content),
			traveler: (content: any) => ProfileEntityMapper.toProfiles(content),
			destination: (content: any) => DestinationMapperEntity.toDestinations(content),
			spot: (content: any) => SpotEntityMapper.toSpots(content),
			guide: (content: any) => SiteEntityMapper.toGuides(content),
			product: (content: any) => ItineraryEntityMapper.toProducts(content),
			links: (content: any) => LinkEntityMapper.toLinks(content),
		}
		const parser = parsers[type]

		return parser ? parser(content) : content
	}
}
