

































import Vue from 'vue'
import Form from '~/components/UI/Form.vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import TextValidator from '~/passporter-services/shared/textValidator'

export default Vue.extend({
	name: 'ForgotPasswordForm',
	components: { PButton, InputText, Form },
	props: {
		error: {
			type: String,
			default: undefined,
		},
	},
	methods: {
		emailValidate(value: string) {
			if (!value) {
				return this.$t('E_error_invalid_email')
			}
			if (!TextValidator.email(value)) {
				return this.$t('E_error_invalid_email')
			}
			return true
		},
	},
})
