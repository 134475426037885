import { ApiNote } from '~/passporter-services/note/apiTypes'
import { HttpClient } from '~/infrastructure/httpClient'

const resource = 'v1/itineraries'

const httpClient = HttpClient.getInstance()

export default (_http: any) => ({
	async createNote(itineraryId: string, note: ApiNote) {
		return await httpClient.post(`${resource}/${itineraryId}/notes/`, note)
	},
	async editNote(itineraryId: string, noteId: string, note: ApiNote) {
		return await httpClient.patch(`${resource}/${itineraryId}/notes/${noteId}/`, note)
	},
	async deleteNote(itineraryId: string, noteId: string) {
		return await httpClient.delete(`${resource}/${itineraryId}/notes/${noteId}/`)
	},
	async addMedia(itineraryId: string, noteId: string, medias: string[]) {
		const params = {
			media_ids: medias,
		}
		return await httpClient.post(`${resource}/${itineraryId}/notes/${noteId}/medias/`, params)
	},
	async getPaginatedNotes(itineraryId: string, page: number, invitationcode?: string) {
		const params = {
			page,
			page_size: 10,
			code: invitationcode,
		}
		return await httpClient.get(`${resource}/${itineraryId}/notes/`, params)
	},
	async getNotesList(itineraryId: string, invitationcode?: string) {
		const params = {
			code: invitationcode,
		}
		return await httpClient.get(`${resource}/${itineraryId}/notes/list/`, params)
	},
	async getMedias(itineraryId: string, noteId: string) {
		return await httpClient.get(`${resource}/${itineraryId}/notes/${noteId}/medias/`)
	},
})
