import { Loader } from '@googlemaps/js-api-loader'
import { GooglePlace } from '~/models/GPlace'

export class GoogleRepository {
	static sessionToken: string

	static async findPlacesPredictions(
		searchTerm: string,
		types: string[] = []
	): Promise<GooglePlace[]> {
		await this.loadGoogle()
		const { AutocompleteService } = (await google.maps.importLibrary(
			'places'
		)) as google.maps.PlacesLibrary
		const autocompleteService = new AutocompleteService()
		const sessionToken = await this.getSessionToken()

		const { predictions } = await autocompleteService.getPlacePredictions({
			input: searchTerm,
			sessionToken,
			types,
		})

		return predictions.map((prediction) => this.parsePrediction(prediction))
	}

	static parsePrediction(prediction: Record<string, any>): GooglePlace {
		return {
			placeId: prediction.place_id,
			name: prediction.structured_formatting.main_text,
			address: prediction.structured_formatting.secondary_text,
		}
	}

	static async getSessionToken(): Promise<string> {
		if (this.sessionToken) return this.sessionToken
		const { AutocompleteSessionToken } = (await google.maps.importLibrary(
			'places'
		)) as google.maps.PlacesLibrary

		this.sessionToken = new AutocompleteSessionToken() as string
		return this.sessionToken
	}

	private static async loadGoogle() {
		const loader = new Loader({
			apiKey: process.env.GOOGLE_MAPS_API_ID!,
			version: 'weekly',
		})

		await loader.load()
	}
}
