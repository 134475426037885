











































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import Avatar from '~/components/PassporterUI/Avatar.vue'
import Traveler from '~/models/Traveler'
import Chip from '~/components/PassporterUI/Chip.vue'

export default Vue.extend({
	name: 'TravelerMiniCard',
	components: { Avatar, Chip },
	props: {
		traveler: {
			type: Object,
			default: undefined,
		} as PropOptions<Traveler | undefined>,
		avatarSize: {
			type: Number,
			default: 32,
		},
		roleHidden: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
	methods: {
		goToProfile() {
			this.$store.dispatch('traveler/openTraveler', { traveler: this.traveler })
		},
	},
})
