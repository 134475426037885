const resource = 'auth'
export default (http: any) => ({
	passwordlessLogin(form: any) {
		return http.post(`${resource}/login/passwordless/`, {
			...form,
		})
	},
	login(form: any) {
		return http.post(`${resource}/login/`, {
			...form,
		})
	},
	loginOAuth(form: any) {
		return http.post(`${resource}/login/social/`, {
			...form,
		})
	},
	register(form: any) {
		return http.post(`${resource}/register/`, {
			...form,
		})
	},
	refresh(refreshToken: string) {
		return http.post(
			`${resource}/refresh/`,
			{
				refresh: refreshToken,
			},
			{
				api: {
					nonAuthenticated: true,
				},
			}
		)
	},
	logout() {
		return http.post(`${resource}/logout/`)
	},
	sendResetPasswordEmail(email: string) {
		return http.post(`${resource}/user/reset_password/`, {
			email,
		})
	},
	resetPassword(data: any) {
		return http.post(`${resource}/user/restore_password/`, data)
	},
})
