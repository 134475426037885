import { Context } from '@nuxt/types'
import itineraryNavigation from '~/navigation/ItineraryNavigation'
import destinationNavigation from '~/navigation/DestinationNavigation'
import GuideNavigation from '~/navigation/GuideNavigation'
import spotNavigation from '~/navigation/SpotNavigation'
import HomeNavigation from '~/navigation/HomeNavigation'
import LegalNavigation from '~/navigation/LegalNavigation'
import VoucherNavigation from '~/navigation/VoucherNavigation'
import profileNavigation from '~/navigation/ProfileNavigation'

export default (context: Context) => ({
	profile: profileNavigation(context),
	spot: spotNavigation(context),
	itinerary: itineraryNavigation(context),
	destination: destinationNavigation(context),
	guide: GuideNavigation(context),
	home: HomeNavigation(context),
	legal: LegalNavigation(context),
	voucher: VoucherNavigation(context),
})
