























import Vue from 'vue'

export default Vue.extend({
	name: 'ItineraryLoader',
	props: {
		travelName: {
			type: String,
			default: '',
		},
		isCreation: {
			type: Boolean,
			default: false,
		},
	},
})
