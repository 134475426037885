import { Category } from '~/models/Category'
import { Guide2 } from '~/models/Guide'
import { BaseRepository } from '../baseRepository'
import { ApiCategory } from './apiTypes'
import { GuideEntityMapper } from './entityMapper'
import { ApiPage } from '../shared/pagination'

export class GuideRepository extends BaseRepository {
	static resource = 'v1/sites'

	static async getCategories(siteId: string): Promise<Category[]> {
		const response: ApiCategory[] = await this.get(`${this.resource}/${siteId}/categories/`, {
			entity: 'guide',
		})

		return GuideEntityMapper.toCategories(response)
	}

	static async getGuideBlocksByCategory(siteId: string, categoryId?: string): Promise<Guide2[]> {
		const params: Record<string, string | number> = {
			page: 0,
			page_size: 10,
		}
		if (categoryId) params.category_id = categoryId
		const response: ApiPage<Guide2> = await this.get(`${this.resource}/${siteId}/guides/`, {
			...params,
		})
		return GuideEntityMapper.toGuides(response.results)
	}
}
