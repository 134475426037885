

















import Vue, { PropOptions } from 'vue'
import PIconAddUser from '~/components/PassporterUI/Icon/PIconAddUser.vue'
import PIconSettingsMenu from '~/components/PassporterUI/Icon/PIconSettingsMenu.vue'
import PTwoToneCalendar from '~/components/PassporterUI/Icon/PTwoToneCalendar.vue'
import PTwoToneFile from '~/components/PassporterUI/Icon/PTwoToneFile.vue'
import PTwoToneFileText from '~/components/PassporterUI/Icon/PTwoToneFileText.vue'

type IconItinerariesMenu = 'file' | 'file-text' | 'calendar' | 'add-user' | 'settings'
export default Vue.extend({
	name: 'ItinerarySideNavItem',
	components: {
		PIconAddUser,
		PTwoToneCalendar,
		PTwoToneFile,
		PTwoToneFileText,
		PIconSettingsMenu,
	},

	props: {
		name: {
			type: String,
			required: true,
		},
		iconName: {
			type: String,
			default: 'file',
		} as PropOptions<IconItinerariesMenu>,
		color: {
			type: String,
			default: 'neutral-900',
		},
	},
})
