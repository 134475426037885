import Itinerary from './Itinerary'
import { serialize } from './helpers/serialize'

interface TravelerData {
	id: string
	name?: string
	username: string
	email: string
	location?: string
	language: string
	referenceType?: string
	picture?: string
	avatar?: string
	cover?: string
	countryName?: string
	role?: string
	previousItineraries?: Itinerary[]
	previousItinerariesNextPage?: number
	nextItineraries?: Itinerary[]
	nextItinerariesNextPage?: number
}

export default class Traveler implements TravelerData {
	private readonly _id
	private readonly _referenceType = 'traveler'
	private readonly _name
	private readonly _email
	private readonly _language
	private readonly _picture
	private readonly _role
	private readonly _username
	private readonly _location
	private readonly _avatar
	private readonly _cover
	private readonly _countryName
	private _previousItineraries
	private _previousItinerariesNextPage
	private _nextItineraries
	private _nextItinerariesNextPage

	constructor(traveler: TravelerData) {
		this._id = traveler.id
		this._name = traveler.name
		this._email = traveler.email
		this._language = traveler.language
		this._username = traveler.username
		this._picture = traveler.picture
		this._avatar = traveler.avatar
		this._cover = traveler.cover
		this._countryName = traveler.countryName
		this._role = traveler.role
		this._location = traveler.location
		this._previousItineraries = serialize(
			Itinerary,
			traveler.previousItineraries
		)
		this._previousItinerariesNextPage = traveler.previousItinerariesNextPage
		this._nextItineraries = serialize(Itinerary, traveler.nextItineraries)
		this._nextItinerariesNextPage = traveler.nextItinerariesNextPage
	}

	get id(): string {
		return this._id
	}

	get referenceType(): 'traveler' {
		return this._referenceType
	}

	get name(): string | undefined {
		return this._name
	}

	get username(): string {
		return this._username
	}

	get email(): string {
		return this._email
	}

	get language(): string {
		return this._language
	}

	get picture(): string | undefined {
		return this._picture
	}

	get role(): string | undefined {
		return this._role
	}

	get location(): string | undefined {
		return this._location
	}

	get avatar(): string | undefined {
		return this._avatar
	}

	get cover(): string | undefined {
		return this._cover
	}

	get countryName(): string | undefined {
		return this._countryName
	}

	get previousItineraries(): Itinerary[] | undefined {
		return this._previousItineraries
	}

	set previousItineraries(itineraries: Itinerary[] | undefined) {
		this._previousItineraries = itineraries
	}

	get previousItinerariesNextPage(): number | undefined {
		return this._previousItinerariesNextPage
	}

	set previousItinerariesNextPage(nextPage: number | undefined) {
		this._previousItinerariesNextPage = nextPage
	}

	get nextItineraries(): Itinerary[] | undefined {
		return this._nextItineraries
	}

	set nextItineraries(itineraries: Itinerary[] | undefined) {
		this._nextItineraries = itineraries
	}

	get nextItinerariesNextPage(): number | undefined {
		return this._nextItinerariesNextPage
	}

	set nextItinerariesNextPage(nextPage: number | undefined) {
		this._nextItinerariesNextPage = nextPage
	}

	toJSON(): TravelerData {
		return {
			id: this.id,
			referenceType: this.referenceType,
			name: this.name,
			email: this.email,
			language: this.language,
			picture: this.picture,
			role: this.role,
			username: this.username,
			location: this.location,
			previousItineraries: this.previousItineraries,
			previousItinerariesNextPage: this.previousItinerariesNextPage,
			nextItineraries: this.previousItineraries,
			nextItinerariesNextPage: this.previousItinerariesNextPage,
		}
	}
}
