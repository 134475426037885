














































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import Dialog, { DialogType } from '~/components/UI/Dialog.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import Divider from '~/components/PassporterUI/Divider.vue'
import TravelersList from '~/components/context/items/itineraries/TravelersList.vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import IconShare from '~/components/UI/Icons/IconShare.vue'
import Itinerary from '~/models/Itinerary'

export type TravelersDialogType = Vue & {
	open: () => void
	openCreate: () => void
}

export default Vue.extend({
	name: 'TravelersDialog',
	components: {
		IconShare,
		InputText,
		TravelersList,
		Divider,
		PButton,
		Dialog,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		itinerary: {
			type: Object,
			required: true,
		} as PropOptions<Itinerary>,
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
			invitationLink: 'itinerary/itineraryInvitationLink',
			user: 'auth/user',
			experiments: 'experiments/experiments',
			destination: 'itinerary/destination',
		}),
	},
	async created() {
		if (this.itinerary?.isEditable) {
			await this.$store.dispatch('itinerary/invite', this.itinerary.id)
		}
	},
	methods: {
		open() {
			const dialog: DialogType = this.$refs.dialog as DialogType
			if (dialog) {
				dialog.open()
				this.sendViewEvent()
				this.$mixpanel?.track('Invite impression', {
					travelerDialog:
						this.experiments.length && this.experiments.at(0).includes(1)
							? this.experiments.at(0)
							: undefined,
				})
			}
		},
		async copyLink() {
			try {
				await navigator.clipboard.writeText(this.invitationLink)
				await this.$store.dispatch('alerts/setSuccess', {
					text: this.$t('Toast_profile_link_copied_to_clipboard') as string,
				})
				this.$mixpanel?.track('Invite copy clicked')
			} catch (e: any) {}
		},
		sendViewEvent(): void {
			const context = this.$tracker.getContext(this.$route?.name)
			const parameters: Record<string, string> = {
				element: 'friends',
				platform: 'web',
				client: 'passporter',
				id: this.itinerary.id ?? '',
				destination: this.destination.name.toLowerCase(),
			}
			if (context) parameters.context = context
			this.$tracker.event('view', parameters)
		},
	},
})
