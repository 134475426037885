import TextValidator from '../shared/textValidator'
import { ProfileRepository } from './repository'
import { Itinerary2, ItineraryStatus } from '~/models/Itinerary'
import { Page } from '~/models/PaginatedItems'
import { Profile } from '~/models/Profile'
import Traveler from '~/models/Traveler'
import { ProfileDestination } from '~/models/ProfileDestination'

export class ProfileService {
	static async getProfile(id: string): Promise<Profile> {
		return await ProfileRepository.getProfile(id)
	}

	static async getTraveler(id: string): Promise<Traveler> {
		return await ProfileRepository.getTraveler(id)
	}

	static async updateProfile(id: string, profile: Profile): Promise<Profile> {
		return await ProfileRepository.updateProfile(id, profile)
	}

	static async getItineraries({
		id,
		page = 0,
		status = 'all',
		destinationId = '',
	}: {
		id: string
		page?: number
		status?: ItineraryStatus
		destinationId?: string
	}): Promise<Page<Itinerary2>> {
		return await ProfileRepository.getItineraries({
			id,
			page,
			status,
			destinationId,
		})
	}

	static async getWishList(profileId: string): Promise<ProfileDestination[]> {
		return await ProfileRepository.getWishList(profileId)
	}

	static async getVisitedDestinations(
		profileId: string,
		hasItineraries?: boolean
	): Promise<ProfileDestination[]> {
		return await ProfileRepository.getVisitedDestinations(profileId, hasItineraries)
	}

	static async checkUsernameAvailability(username: string): Promise<boolean> {
		return await ProfileRepository.checkUsernameAvailability(username)
	}

	static async addWishlist(userId: string, wishlist: Array<string>): Promise<void> {
		await ProfileRepository.addWishlist(userId, wishlist)
	}

	static async addVisitedList(userId: string, visitedList: Array<string>): Promise<void> {
		await ProfileRepository.addVisitedList(userId, visitedList)
	}

	static async removeWishlistDestination(userId: string, destinationId: string): Promise<void> {
		await ProfileRepository.removeWishlistDestination(userId, destinationId)
	}

	static async removeVisitedDestination(userId: string, destinationId: string): Promise<void> {
		await ProfileRepository.removeVisitedDestination(userId, destinationId)
	}

	static async validateProfile(
		profile: Profile,
		oldProfile: Profile
	): Promise<Partial<Record<keyof Profile, string>> | undefined> {
		const profileErrors: Partial<Record<keyof Profile, string>> = {}

		profileErrors.name = this.retrieveProfileNameErrors(profile)
		profileErrors.username = await this.retrieveUsernameErrors(profile, oldProfile)
		profileErrors.birthYear = this.retrieveYearErrors(profile)
		profileErrors.country = this.retrieveCountryErrors(profile)

		const hasErrors = Object.values(profileErrors).some((value) => Boolean(value))
		if (!hasErrors) return

		return profileErrors
	}

	private static retrieveProfileNameErrors(profile: Profile): string {
		if (!profile.name) return 'empty_value'
		if (!TextValidator.profileName(profile.name)) return 'invalid_format'
		return ''
	}

	private static async retrieveUsernameErrors(
		profile: Profile,
		oldProfile: Profile
	): Promise<string> {
		if (!profile.username) return 'empty_value'
		if (!TextValidator.username(profile.username)) return 'invalid_format'
		const unChanged: boolean = profile.username === oldProfile.username
		if (unChanged) return ''
		const isAvailable: boolean = await ProfileService.checkUsernameAvailability(profile.username)
		if (!isAvailable) return 'unavailable'
		return ''
	}

	private static retrieveYearErrors(profile: Profile): string {
		if (!profile.birthYear) return 'empty_value'
		const isValidYear: boolean = TextValidator.year(profile.birthYear)
		const isOutOfRange: boolean = !TextValidator.yearInRange(profile.birthYear)
		if (!isValidYear || isOutOfRange) return 'invalid_format'
		return ''
	}

	private static retrieveCountryErrors(profile: Profile) {
		if (!profile.country) return 'empty_value'
		return ''
	}
}
