

































import Vue from 'vue'
import { PropOptions } from 'vue/types/umd'
import Avatar from '~/components/PassporterUI/Avatar.vue'
import PIconAdd from '~/components/PassporterUI/Icon/PIconAdd.vue'

export default Vue.extend({
	name: 'TravelersCount',
	components: {
		Avatar,
		PIconAdd,
	},
	props: {
		avatarUrls: {
			type: Array,
			required: true,
		} as PropOptions<string[]>,
		travelersAmount: {
			type: Number,
			required: true,
		},
		addButton: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		showAddButton(): boolean {
			return this.addButton && this.travelersCount <= 1
		},
		travelersCount(): number {
			return this.travelersAmount
		},
	},
	methods: {
		retrieveTravelersCount(): number {
			const notCountingOwner: number = 1
			return this.travelersCount - notCountingOwner
		},
	},
})
