export default class GPlace {
	referenceType?: 'gplace'
	placeId: string
	name: string
	address: string
	location: { latitude: number; longitude: number } | undefined

	constructor(gPlace: GPlace) {
		this.referenceType = 'gplace'
		this.location = gPlace.location
		this.placeId = gPlace.placeId
		this.name = gPlace.name
		this.address = gPlace.address
	}
}

export type GooglePlace = {
	placeId: string
	name: string
	address?: string
}
