import Calendar from '../shared/calendar'
import { ApiAvailability, ApiProfile, ApiProfileDestination, ApiSubscription } from './apiTypes'
import Traveler from '~/models/Traveler'
import { Profile, Subscription } from '~/models/Profile'
import { ProfileDestination } from '~/models/ProfileDestination'

export class ProfileEntityMapper {
	static toProfile(profile: ApiProfile): Profile {
		return {
			id: profile.id,
			name: profile.name,
			username: profile.username,
			cover: profile.cover_url,
			avatar: profile.picture_url,
			email: profile.email,
			location: profile.location_name,
			country: profile.country_id,
			countryName: profile.country_name,
			birthYear: profile.birth_date && Calendar.getYear(profile.birth_date),
		}
	}

	static toProfiles(profiles: ApiProfile[]): Profile[] {
		return profiles.map((profile: ApiProfile) => this.toProfile(profile))
	}

	static toTraveler(traveler: any) {
		return new Traveler({
			id: traveler.user_id ?? traveler.id,
			name: traveler.name,
			username: traveler.username,
			picture: traveler.picture ?? traveler.picture_url,
			role: traveler.role,
			email: traveler.email,
			language: traveler.language,
			avatar: traveler.picture_url,
			cover: traveler.cover_url,
			countryName: traveler.country_name,
		})
	}

	static toTravelers(travelers: any[]) {
		return travelers.map((traveler) => this.toTraveler(traveler))
	}

	static toSubscription(apiSubscription: ApiSubscription): Subscription {
		return { isSubscribed: apiSubscription.subscribed }
	}

	static toAvailable(data: ApiAvailability): boolean {
		return data.available
	}

	static toProfileDestination(profileDestination: ApiProfileDestination): ProfileDestination {
		return {
			id: profileDestination.destination_id,
			name: profileDestination.destination_name,
			countryIso: profileDestination.country_iso_code,
			countryStampUrl: profileDestination.country_stamp_url,
			coverUrl: profileDestination.cover_url,
		}
	}

	static toProfileDestinations(profileDestinations: ApiProfileDestination[]): ProfileDestination[] {
		return profileDestinations.map((profileDestination) =>
			this.toProfileDestination(profileDestination)
		)
	}
}
