



































import Vue from 'vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import { SelectItem } from '~/components/UI/Select.vue'
import TextCleaner from '~/passporter-services/shared/textCleaner'
import TextValidator from '~/passporter-services/shared/textValidator'

export default Vue.extend({
	name: 'YearPicker',
	components: {
		InputText,
	},
	props: {
		value: {
			type: String,
			default: '',
		},
		isMobile: {
			type: Boolean,
			required: true,
		},
		error: {
			type: String,
			default: '',
		},
		small: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const showResults = false as boolean
		const options: Array<SelectItem> = []
		const minCharsToSearch: number = 1
		const yearsList: Array<SelectItem> = []
		const localValue: string = this.value
		const selectedYear: string = ''

		return {
			showResults,
			options,
			minCharsToSearch,
			yearsList,
			localValue,
			selectedYear,
		}
	},
	mounted() {
		this.yearsList = this.retrieveOptions()
	},
	methods: {
		makeSureOnlyNumbers() {
			this.localValue = TextCleaner.onlyNumbers(this.localValue)
		},
		inputText(text: string) {
			const hasMinChars = text.length > this.minCharsToSearch
			if (!hasMinChars) return
			if (!TextValidator.onlyNumbers(text)) return
			this.options = this.yearsList.filter((option) => option.value.startsWith(text))
		},
		select(option: SelectItem) {
			this.localValue = option.text
			this.showResults = false
			this.selectedYear = this.localValue
			this.$emit('change', this.localValue)
		},
		onFocus() {
			this.showResults = true
			this.options = this.yearsList
			if (this.selectedYear) return (this.localValue = this.selectedYear)
		},
		onFocusOut() {
			setTimeout(() => {
				this.showResults = false
			}, 300)
			if (this.selectedYear !== this.localValue) {
				this.localValue = ''
				this.selectedYear = ''
				this.$emit('change', this.localValue)
				return
			}
			if (this.selectedYear) {
				this.localValue = this.selectedYear
			}
		},
		retrieveOptions(): Array<SelectItem> {
			const currentYear: number = new Date().getFullYear()
			const hundredYearsAgo: number = currentYear - 100
			const options: Array<SelectItem> = []
			for (let year = currentYear; year >= hundredYearsAgo; year--) {
				options.push({
					text: year.toString(),
					value: year.toString(),
				})
			}
			return options
		},
	},
})
