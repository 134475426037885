import { Page } from '../../models/PaginatedItems'
import { ItineraryEntityMapper } from './entityMapper'
import { ItineraryRepository } from './repository'
import { Itinerary2 as Itinerary } from '~/models/Itinerary'
import Media from '~/models/Media'

export class ItineraryService {
	repository: any
	constructor(repository: any) {
		this.repository = repository
	}

	async getItinerary(itineraryId: string, invitationCode?: string): Promise<Itinerary> {
		const options: Record<string, string> = { itineraryId }
		if (invitationCode) {
			options.code = invitationCode
		}
		const res = await this.repository.getItinerary(options)
		const { data } = res
		return ItineraryEntityMapper.toItinerary(data)
	}

	async updateItineraryState(id: string, state: string): Promise<void> {
		await this.repository.edit(id, { state })
	}

	async getSpots(
		itineraryId: string,
		itineraryGroupId?: string | null,
		page?: number,
		pageSize?: number,
		code?: string,
		categoryIds?: string[]
	): Promise<any> {
		const response = await this.repository.getItinerarySpots({
			itineraryId,
			page,
			pageSize,
			code,
			itineraryGroupId,
			categoryIds,
		})
		const results = response?.data?.results
		return ItineraryEntityMapper.parseItinerarySpots(results)
	}

	async setSpotGroup(
		itineraryId: string,
		spotId: string,
		groupId: string,
		isSpotReference?: boolean
	) {
		await this.repository.aggregateSpots(itineraryId, [
			{
				spotId,
				groupsIds: [groupId],
				isReference: isSpotReference,
			},
		])
	}

	async removeSpotFromGroup(itineraryId: string, spotId: string, isSpotReference?: boolean) {
		await this.repository.aggregateSpots(itineraryId, [
			{
				spotId,
				groupsIds: [],
				isReference: isSpotReference,
			},
		])
	}

	async deleteGroup(itineraryId: string, groupId: string) {
		await this.repository.deleteGroup({ itineraryId, id: groupId })
	}

	static async addMedia(itineraryId: string, mediaId: string): Promise<Media> {
		return await ItineraryRepository.addMedia(itineraryId, mediaId)
	}

	static async getMedias(itineraryId: string): Promise<Page<Media>> {
		return await ItineraryRepository.getMedias(itineraryId)
	}

	static async deleteMedia(itineraryId: string, mediaId: string): Promise<void> {
		return await ItineraryRepository.deleteMedia(itineraryId, mediaId)
	}
}
