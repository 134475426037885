





























import Vue from 'vue'
import { PropOptions } from 'vue/types/umd'
import PIconPlan from './Icon/PIconPlan.vue'
import PIconPlane from './Icon/PIconPlane.vue'
import Responsive from '~/models/Responsive'

type TabIcon = 'map' | 'plane'

export type Tab = {
	title: string
	icon?: TabIcon
	key: string
}

export default Vue.extend({
	name: 'Tabs',
	components: {
		PIconPlan,
		PIconPlane,
	},
	props: {
		default: {
			type: String,
			default: undefined,
		},
		tabs: {
			type: Array,
			default: undefined,
		} as PropOptions<Array<Tab>>,
		screenSize: {
			type: Object,
			required: true,
		} as PropOptions<Responsive>,
	},
	data() {
		return {
			currentTab: this.default || this.tabs[0].key,
		}
	},
	methods: {
		goToTab(key: string) {
			this.currentTab = key
		},
		printIcon(icon: TabIcon) {
			const icons = {
				map: 'PIconPlan',
				plane: 'PIconPlane',
			}
			return icons[icon]
		},
	},
})
