export default class TextValidator {
	static username(text: string): boolean {
		const minChars = 3
		const maxChars = 50
		const hasExpectedLength = text.length >= minChars && text.length <= maxChars
		const pattern = /^[a-z0-9._]{3,50}$/
		const hasSpecialChars = pattern.test(text)
		return hasExpectedLength && hasSpecialChars
	}

	static profileName(text: string): boolean {
		const min: number = 3
		const max: number = 140
		const lengthOfName: number = text.length
		return lengthOfName >= min && lengthOfName <= max
	}

	static email(text: string): boolean {
		const pattern = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
		return pattern.test(text)
	}

	static year(text: string): boolean {
		const pattern = /^\d{4}$/
		return pattern.test(text)
	}

	static yearExceeded(text: string): boolean {
		return text.length > 4
	}

	static yearInRange(text: string): boolean {
		const year = Number(text)
		const currentYear: number = new Date().getFullYear()
		const hundredYearsAgo: number = currentYear - 100
		const isInRange = year < currentYear && year > hundredYearsAgo
		return isInRange
	}

	static onlyNumbers(text: string): boolean {
		const pattern = /[0-9]$/
		return pattern.test(text)
	}
}
