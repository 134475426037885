


















import Vue, { PropOptions } from 'vue'
import Itinerary from '~/models/Itinerary'
import PIconChevron from '~/components/PassporterUI/Icon/PIconChevron.vue'
import PButton from '~/components/PassporterUI/PButton.vue'

export default Vue.extend({
	name: 'ItineraryGoBackButton',
	components: { PIconChevron, PButton },
	props: {
		itinerary: {
			type: Object,
			required: true,
		} as PropOptions<Itinerary>,
		onlyIcon: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		goBack() {
			const comeFromInvitation =
				// @ts-ignore
				this.$router.history && this.$router.history._startLocation.includes('invitation=')
			const inInvitationRoute = this.$route.query.invitation

			if (inInvitationRoute || comeFromInvitation) {
				const route = this.$navigation.profile.getProfile()
				window.location.href = route
				return
			}

			this.$router.back()
		},
	},
})
