import { DestinationMapperEntity } from './toEntity'
import { Destination2 as Destination } from '~/models/Destination'
import CivitatisActivity from '~/models/CivitatisActivity'
import { Spot2 as Spot } from '~/models/Spot'
import { DestinationRepository } from './repository'

export class DestinationService {
	repository: any
	constructor(repository?: any) {
		this.repository = repository
	}

	async obtainDestination(id: string): Promise<Destination> {
		const response = await this.repository.getDestination(id)
		const { data } = response
		return DestinationMapperEntity.toDestination(data)
	}

	async find(placeId: string): Promise<Destination> {
		const response = await this.repository.find(placeId)
		const { data } = response
		return DestinationMapperEntity.toDestination(data)
	}

	pushSpots(destination: Destination, spots?: Spot[] | undefined, nextPage?: number): void {
		spots?.forEach((spot: Spot) => {
			const indexed = destination.spots?.find((indexedSpot: Spot) => indexedSpot.id === spot.id)
			if (!indexed) {
				destination.spots?.push(spot)
			}
		})
		destination.spotsNextPage = nextPage
	}

	pushCivitatis(
		destination: Destination,
		civitatisActivities?: CivitatisActivity[] | undefined,
		nextPage?: number
	): void {
		civitatisActivities?.forEach((civitatisActivity: CivitatisActivity) => {
			const indexed = destination.civitatis?.find(
				(indexedCivitatisActivity: CivitatisActivity) =>
					indexedCivitatisActivity.id === civitatisActivity.id
			)
			if (!indexed) {
				destination.civitatis?.push(civitatisActivity)
			}
		})
		destination.civitatisNextPage = nextPage
	}

	static async getDestination(placeId: string): Promise<Destination> {
		return await DestinationRepository.getDestination(placeId)
	}

	static async find(placeId: string): Promise<Destination> {
		return await DestinationRepository.find(placeId)
	}
}
