
















































import Vue, { PropOptions } from 'vue'
import GoogleBrowser from '~/components/context/shared/browser/GoogleBrowser.vue'
import ProfileName from '~/components/context/profile/ProfileName.vue'
import ProfilePicture from '~/components/context/profile/ProfilePicture.vue'
import ProfileCoverSelector from '~/components/context/profile/ProfileCoverSelector.vue'
import Username from '~/components/context/profile/Username.vue'
import YearPicker from '~/components/PassporterUI/YearPicker.vue'
import Form from '~/components/UI/Form.vue'
import { Destination2 } from '~/models/Destination'
import { Profile } from '~/models/Profile'
import { Spot2 } from '~/models/Spot'

export default Vue.extend({
	name: 'ProfileForm',
	components: {
		Form,
		ProfileName,
		YearPicker,
		ProfilePicture,
		Username,
		GoogleBrowser,
		ProfileCoverSelector,
	},
	props: {
		profile: {
			type: Object,
			required: true,
		} as PropOptions<Profile>,
		isMobile: {
			type: Boolean,
			required: true,
		},
		formErrors: {
			type: Object,
			default: undefined,
		} as PropOptions<Partial<Record<keyof Profile, string>> | undefined>,
		profileEdition: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const form: Profile = { ...this.profile }
		const translatedErrors = {} as Partial<Record<keyof Profile, string>>
		return {
			form,
			translatedErrors,
		}
	},
	watch: {
		formErrors(newVal): void {
			this.translateErrors(newVal)
		},
	},
	methods: {
		selectPicture(picture: { imageUrl: string; mediaId: string } | undefined): void {
			this.form.avatarId = picture?.mediaId
			this.form.avatar = picture?.imageUrl
			this.$emit('change', this.form)
		},
		selectCover(cover: { imageUrl: string; mediaId: string } | undefined): void {
			this.form.coverId = cover?.mediaId
			this.form.cover = cover?.imageUrl
			this.$emit('change', this.form)
		},
		profileNameChange(text: string): void {
			this.form.name = text
			this.$emit('change', this.form)
		},
		usernameChange(text: string): void {
			this.form.username = text
			this.$emit('change', this.form)
		},
		yearSelected(year: string): void {
			this.form.birthYear = year
			this.$emit('change', this.form)
		},
		selectCountry(country: Spot2 | Destination2): void {
			this.form.country = country.id!
			this.form.countryName = country.name!
			this.$emit('change', this.form)
		},
		translateErrors(errors: Partial<Record<keyof Profile, string>>): void {
			const translated: Partial<Record<keyof Profile, string>> = {
				name: this.translateNameError(errors.name!),
				username: this.translateUserNameError(errors.username!),
				country: this.translateCountryError(errors.country!),
				birthYear: this.translateBirthYearError(errors.birthYear!),
			}
			this.translatedErrors = translated
		},
		translateNameError(error: string): string {
			const errors: Record<string, string> = {
				empty_value: this.$t('input_errorMessage_fieldRequired') as string,
				invalid_format: this.$t('name_description_minimumCharacters') as string,
			}
			return errors[error] || errors[0]
		},
		translateUserNameError(error: string): string {
			const errors: Record<string, string> = {
				unavailable: this.$t('input_errorMessage_notAvailable') as string,
				empty_value: this.$t('input_errorMessage_fieldRequired') as string,
				invalid_format: this.$t('input_description_lengthAndFormat') as string,
			}
			return errors[error] || errors[0]
		},
		translateCountryError(error: string): string {
			const errors: Record<string, string> = {
				empty_value: this.$t('input_errorMessage_fieldRequired') as string,
			}
			return errors[error] || errors[0]
		},
		translateBirthYearError(error: string): string {
			const errors: Record<string, string> = {
				empty_value: this.$t('input_errorMessage_fieldRequired') as string,
				invalid_format: this.$t('input_errorMessage_invalidYear') as string,
			}
			return errors[error] || errors[0]
		},
	},
})
