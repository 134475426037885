import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store/index'
import Traveler from '~/models/Traveler'
import Itinerary from '~/models/Itinerary'
import { ProfileService } from '~/passporter-services/profile/service'
import { serialize } from '~/models/helpers/serialize'

interface TravelerInitState {
	traveler?: Traveler
}

const initState = (): TravelerInitState => ({
	traveler: undefined,
})

export const state = initState
export type TravelerModuleState = ReturnType<typeof state>

export const mutations: MutationTree<TravelerModuleState> = {
	setTraveler(state, traveler: Traveler) {
		state.traveler = traveler
	},
	serializeTraveler(state) {
		state.traveler = serialize(Traveler, state.traveler)
	},
	setPreviousItineraries(state, { nextPage, itineraries }) {
		if (state.traveler) {
			state.traveler.previousItinerariesNextPage = nextPage
			state.traveler.previousItineraries = itineraries
		}
	},
	pushPreviousItineraries(state, { nextPage, itineraries }) {
		if (!state.traveler) return

		state.traveler.previousItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.traveler!.previousItineraries) {
				state.traveler!.previousItineraries = [itinerary]
			} else if (
				!state.traveler!.previousItineraries.some(
					(savedItinerary) => savedItinerary.id === itinerary.id
				)
			) {
				state.traveler!.previousItineraries.push(itinerary)
			}
		})
	},
	setNextItineraries(state, { nextPage, itineraries }) {
		if (state.traveler) {
			state.traveler.nextItinerariesNextPage = nextPage
			state.traveler.nextItineraries = itineraries
		}
	},
	pushNextItineraries(state, { nextPage, itineraries }) {
		if (!state.traveler) return

		state.traveler.nextItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.traveler!.nextItineraries) {
				state.traveler!.nextItineraries = [itinerary]
			} else if (
				!state.traveler!.nextItineraries.some(
					(savedItinerary) => savedItinerary.id === itinerary.id
				)
			) {
				state.traveler!.nextItineraries.push(itinerary)
			}
		})
	},
}
export const actions: ActionTree<TravelerModuleState, RootState> = {
	serialize({ commit }) {
		commit('serializeTraveler')
	},
	async getTraveler({ commit, dispatch }, travelerId: string) {
		try {
			const traveler = await ProfileService.getTraveler(travelerId)
			commit('setTraveler', traveler)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getPreviousItineraries({ commit, state, dispatch }, travelerId: string) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: travelerId ?? state.traveler?.id,
				page: 0,
				status: 'previous',
			})
			commit('setPreviousItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setPreviousItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMorePreviousItineraries({ commit, getters, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: state.traveler!.id,
				page: getters.previousMoreItineraries,
				status: 'previous',
			})
			commit('pushPreviousItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getNextItineraries({ commit, state, dispatch }, travelerId: string) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: travelerId ?? state.traveler?.id,
				page: 0,
				status: 'next',
			})
			commit('setNextItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setNextItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreNextItineraries({ commit, getters, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: state.traveler!.id,
				page: getters.nextMoreItineraries,
				status: 'next',
			})
			commit('pushNextItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async openTraveler(
		{ dispatch },
		{
			traveler,
			travelerId,
		}: {
			traveler?: Traveler
			travelerId?: string
		}
	) {
		try {
			const route = this.$navigation.profile.getProfile(traveler?.id || travelerId)
			window.location.href = route
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
}
export const getters: GetterTree<TravelerModuleState, RootState> = {
	traveler: (state): Traveler | undefined => {
		return state.traveler
	},
	previousItineraries: (state): Itinerary[] | undefined => {
		return state.traveler?.previousItineraries
	},
	previousMoreItineraries: (state) => {
		return state.traveler?.previousItinerariesNextPage
	},
	nextItineraries: (state): Itinerary[] | undefined => {
		return state.traveler?.nextItineraries
	},
	nextMoreItineraries: (state) => {
		return state.traveler?.nextItinerariesNextPage
	},
}
