import MediaRepository from './repository'
import Media from '~/models/Media'

export default class MediaService {
	public static async upload(file: File): Promise<Media> {
		return await MediaRepository.upload(file)
	}

	public static async delete(id: string): Promise<void> {
		await MediaRepository.deleteMedia(id)
	}
}
