import { Spot2 } from '~/models/Spot'
import { BaseRepository } from '../baseRepository'
import { ApiSpot } from './apiTypes'
import { SpotEntityMapper } from './entityMapper'

export class SpotRepository extends BaseRepository {
	private static resource = 'v1/spots'

	static async find(placeId: string): Promise<Spot2> {
		const response: ApiSpot = await this.get(`${this.resource}/find`, {
			place_id: placeId,
		})
		return SpotEntityMapper.toSpot(response)
	}
}
