













































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Map from '~/components/UI/Map.vue'
import Alerts from '~/components/context/core/Alerts.vue'
import RootDialogs from '~/components/context/core/RootDialogs.vue'
import ItineraryBrowser from '~/components/context/items/itineraries/ItineraryBrowser.vue'
import SpotMapPopup from '~/components/context/items/spots/SpotMapPopup.vue'
import { Marker2 as Marker } from '~/models/Marker'
import ItinerarySideNav from '~/components/context/items/itineraries/ItinerarySideNav.vue'
import ItineraryGoBackButton from '~/components/context/items/itineraries/ItineraryGoBackButton.vue'

export default Vue.extend({
	name: 'MapLayout',
	components: {
		ItineraryBrowser,
		Map,
		Alerts,
		RootDialogs,
		SpotMapPopup,
		ItinerarySideNav,
		ItineraryGoBackButton,
	},
	data() {
		return {
			paddingX: 72,
		}
	},
	computed: {
		...mapGetters({
			itinerary: 'itinerary/itinerary',
			browserMarkers: 'maps/browserMarkers',
			screenSize: 'screenSize',
			featureFlags: 'abtest/featureFlags',
		}),
		obtainMarkers(): Marker[] {
			let result: Marker[] = []
			if (this.itinerary?.markers) result = this.itinerary?.markers
			if (this.browserMarkers) result = [...this.browserMarkers, ...result]
			return result
		},
		halfScreenWidth(): number | undefined {
			if (!process.client) return undefined
			return window.innerWidth / 2
		},
		experimentEnabled(): boolean {
			const ITINERARY_BROWSER_FLAG = 'PAS_632_itinerary_browser'
			const isBookingsView =
				this.$route.name?.split('___')[0] === 'itineraries-itinerary_id-bookings'
			return this.featureFlags?.[ITINERARY_BROWSER_FLAG] && !isBookingsView && !this.screenSize.xs
		},
		route(): string | undefined {
			return this.$route.name?.match(/(.*)___[a-z]{2}/)?.[1]
		},
		isItineraryOwnerOrGuest(): boolean {
			if (!this.route) return false
			const isTripPreview = this.route.includes('tripOverview')
			const isPreviewView = this.route.includes('preview')
			return this.itinerary?.isEditable || (isTripPreview && isPreviewView)
		},
		hasItinerary(): boolean {
			if (!this.itinerary) return false
			return Object.keys(this.itinerary).length > 0
		},
	},
	methods: {
		async openItineraryWithMap() {
			const route = this.$navigation.itinerary.getRoute(this.itinerary.id, {})
			await this.$router.push(route)
		},
		async loadMarkerData(marker: Marker) {
			if (!marker.data) {
				await this.$store.dispatch('itinerary/getItineraryMarkerData', marker)
				await this.$store.dispatch('spot/getSpotMedias', marker.data)
			}
		},
	},
})
