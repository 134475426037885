









































import Vue from 'vue'
import { mapGetters } from 'vuex'
import LazyLoad from '~/components/PassporterUI/LazyLoad.vue'
import Container from '~/components/PassporterUI/Container.vue'

export default Vue.extend({
	name: 'Cover',
	components: { Container, LazyLoad },
	props: {
		src: {
			type: String,
			default: undefined,
		},
		height: {
			type: [String, Number],
			default: undefined,
		},
		width: {
			type: [String, Number],
			default: undefined,
		},
		scale: {
			type: Number,
			default: undefined,
		},
		contentClass: {
			type: [String, Object],
			default: undefined,
		},
		parallax: {
			type: Boolean,
			default: false,
		},
		original: {
			type: Boolean,
			default: false,
		},
		lazy: {
			type: Boolean,
			default: true,
		},
		imageShadow: {
			type: Boolean,
			default: false,
		},
		activeRadio: {
			type: Boolean,
			default: false,
		},
		relative: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			id: null,
			rendered: false,
			intersected: false,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		coverStyle(): { [key: string]: string | undefined } {
			return {
				'background-image': this.src
					? `url(${this.original ? this.src : this.responsiveUrl()})`
					: undefined,
				'background-attachment': this.parallax ? 'fixed' : undefined,
				'background-repeat': 'no-repeat',
				'background-size': 'cover',
				'background-position': 'center center',
			}
		},
		containerStyle(): Object {
			const style = {} as any
			if (this.height) {
				if (/^\d+$/.test(this.height as string)) {
					style.height = `${this.height}px`
					style['min-height'] = `${this.height}px`
				} else {
					style.height = this.height
					style['min-height'] = this.height
				}
			} else {
				style['min-height'] = 'auto'
			}
			if (this.width) {
				if (/^\d+$/.test(this.width as string)) {
					style.width = `${this.width}px`
					style['min-width'] = `${this.width}px`
				} else {
					style.width = this.width
					style['min-width'] = this.width
				}
			}
			return style
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.rendered = true
		})
	},
	methods: {
		responsiveUrl(): string | undefined {
			const _box = (this.$refs.container as any)?.$el
			if (_box) {
				const imageOptions = {
					format: 'webp',
					url: this.src,
					size: {
						width: _box.offsetWidth,
						height: _box.offsetHeight,
						scale: this.scale,
					},
					options: {
						lossless: false,
						quality: 80,
						nearLossless: false,
						smartSubsample: false,
					},
				}
				if (imageOptions.size) {
					const scale = imageOptions.size.scale ?? 1.5
					const imagePath = /(https?:\/\/[^/]*)\/(.*)/.exec(imageOptions.url)
					const domain = imagePath?.[1]
					if (domain === process.env.API_CDN_URL) {
						const imageKey = imagePath?.[2]
						// options documentation: https://sharp.pixelplumbing.com/api-output#webp
						let options
						const resize = {
							width: imageOptions.size.width * scale,
							height: imageOptions.size.height && imageOptions.size.height * scale,
						}
						if (imageOptions.format === 'png') {
							options = {
								resize,
								png: imageOptions.options,
							}
						} else {
							options = {
								resize,
								webp: imageOptions.options,
							}
						}
						const imageRequest = JSON.stringify({
							key: imageKey,
							edits: options,
						})
						return `${process.env.API_IMAGES_URL}/${btoa(imageRequest)}`
					} else {
						return imageOptions.url
					}
				}
			} else {
				return undefined
			}
		},
	},
})
