
























import Vue from 'vue'
import { mapGetters } from 'vuex'
import PButton from '~/components/PassporterUI/PButton.vue'
export default Vue.extend({
	name: 'Oauth',
	components: { PButton },
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		showAll: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loader: false,
			googleButton: null as HTMLElement | null,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		disabledButtons(): boolean {
			return this.disabled || this.loader
		},
	},
	mounted() {
		this.waitForGoogle(() => {
			this.initGoogleIdentity()
			this.renderGoogleButton()
		})
	},
	methods: {
		initGoogleIdentity() {
			const _google = google as any
			_google.accounts.id.initialize({
				client_id: process.env.OAUTH_GOOGLE_CLIENT_ID,
				ux_mode: 'popup',
				callback: async (response: any) => {
					const { credential: token } = response
					await this.$store.dispatch('auth/loginOAuth', {
						provider: 'google',
						token,
					})
				},
			})
		},
		waitForGoogle(callback: Function) {
			const checkGoogle = setInterval(() => {
				if (window.google) {
					clearInterval(checkGoogle)
					callback()
				}
			}, 100)
		},
		renderGoogleButton() {
			const _google = google as any
			const parent = document.getElementById('google_btn')
			if (!parent) return
			_google.accounts.id.renderButton(parent, {})
			this.googleButton = parent.querySelector('div[role=button]')
		},
		googleLogin() {
			this.loader = true
			this.$mixpanel?.track('signup google click')
			try {
				if (!this.googleButton) return
				this.googleButton.click()
			} catch (e: any) {
			} finally {
				this.checkAuth()
				this.loader = false
			}
		},
		checkAuth() {
			if (this.$store.getters['auth/isAuthenticated']) {
				this.$emit('authenticated')
			}
		},
	},
})
