



























import Vue from 'vue'
import { mapGetters } from 'vuex'
import ProfileForm from '~/components/context/profile/ProfileForm.vue'
import Dialog, { DialogType } from '~/components/PassporterUI/Dialog.vue'
import { Profile } from '~/models/Profile'
import { ProfileService } from '~/passporter-services/profile/service'
import PButton from '~/components/PassporterUI/PButton.vue'

export default Vue.extend({
	name: 'ProfileEdit',
	components: { ProfileForm, Dialog, PButton },
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const form: Profile = {}
		const formErrors: Partial<Record<keyof Profile, string>> = {}
		return { form, formErrors }
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			screenSize: 'screenSize',
		}),
	},
	methods: {
		open() {
			this.form = { ...this.user }
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.open()
		},
		handleProfile(profile: Profile): void {
			Object.keys(profile).forEach((key) => {
				const theKey = key as keyof Profile
				if (profile[theKey] !== this.form[theKey]) {
					this.formErrors[theKey] = ''
				}
			})
			this.form = { ...profile }
		},
		async save(): Promise<void> {
			const hasErrors = await this.validateProfile()
			if (hasErrors) return
			try {
				await ProfileService.updateProfile(this.user.id, this.form)
				await this.$store.dispatch('auth/setProfile', this.form)
				await this.$store.dispatch('alerts/setSuccess', {
					text: this.$t('Profile_edit_update_profile_success'),
				})
				this.close()
			} catch {
				await this.$store.dispatch('alerts/setError', this.$t('Profile_edit_update_profile_error'))
			}
		},
		async validateProfile() {
			const errors = await ProfileService.validateProfile(this.form, this.user)
			if (errors) this.formErrors = errors
			return errors
		},
		cancel() {
			this.$emit('cancel')
			this.close()
		},
		close() {
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.close()
		},
	},
})
