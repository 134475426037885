import { Category } from '~/models/Category'
import { Guide2 as Guide, InspirationSource } from '~/models/Guide'
import { ApiCategory } from './apiTypes'

export class GuideEntityMapper {
	static toGuide(content: any): Guide {
		return {
			id: content.id,
			title: content.title,
			subtitle: content.subtitle,
			description: content.description,
			state: content.state,
			spotsNumber: content.num_spots,
			destinations: content.num_destinations,
			travelers: content.num_travelers,
			notes: content.num_notes,
			views: content.num_views,
			covers: content.covers,
			creatorId: content.creator_id,
			travelersPictures: content.travelers_pictures,
			inspirationSources: content.sources ? this.toInspirationSources(content.sources) : undefined,
		} as Guide
	}

	static toCategory(content: ApiCategory): Category {
		return {
			id: content.id,
			name: content.name,
			logo: content.logo,
		}
	}

	static toInspirationSources(sources: Record<string, any>[]): InspirationSource[] {
		const result = sources.map((source) => {
			const item: InspirationSource = {
				name: source.name,
				url: source.url,
			}
			if (source.logo) {
				item.logo = source.logo
			}
			return item
		})
		return result
	}

	static toGuides = (contents: Record<string, any>[]): Guide[] => {
		return contents.map((content) => {
			return this.toGuide(content)
		})
	}

	static toCategories = (contents: ApiCategory[]): Category[] => {
		return contents.map((content) => {
			return this.toCategory(content)
		})
	}
}
