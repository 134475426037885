export type Locale =
	| 'ca'
	| 'de'
	| 'el-GR'
	| 'en'
	| 'es'
	| 'fr'
	| 'it'
	| 'ja'
	| 'ko'
	| 'nl-NL'
	| 'pl'
	| 'pt'
	| 'pt-BR'
	| 'ru'
	| 'tr-TR'
	| 'zh'

export const DEFAULT_LOCALE: Locale = 'en'
