




























import Vue from 'vue'
import { PropOptions } from 'vue/types/umd'
import { GooglePlace } from '~/models/GPlace'

export default Vue.extend({
	name: 'GoogleResultList',
	props: {
		predictions: {
			type: Array,
			required: true,
		} as PropOptions<GooglePlace[]>,
		searchText: {
			type: String,
			default: '',
		},
	},
	methods: {
		hasMinSearchTextLength(): boolean {
			return this.searchText.length >= 3
		},
	},
})
