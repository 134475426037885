import Media from '~/models/Media'
import { ApiMedia } from '~/passporter-services/media/apitype'

export class MediaEntityMapper {
	static toMedia = (media: any) => {
		return new Media({
			created_at: media.created,
			id: media.id,
			metadata: media.metadata,
			name: media.name,
			ownerId: media.owner_id,
			size: media.size,
			state: media.state,
			type: media.type,
			url: media.url,
			pending: media.pending,
		})
	}

	static toMedias = (apiMedias: ApiMedia[]) => {
		const mappedMedias = apiMedias.map((apiMedia: ApiMedia) => this.toMedia(apiMedia))
		return mappedMedias.filter((media: Media) => media.state === 'ready')
	}
}
