import { Context } from '@nuxt/types'

export default (context: Context) => ({
	getProfile(publicProfileId?: string): string {
		return newProfileBaseRoute(context, publicProfileId)
	},
	getMyItinerariesSection(publicProfileId?: string): string {
		const baseRoute = newProfileBaseRoute(context, publicProfileId)
		return `${baseRoute}?section=travels`
	},
	getItinerariesGrid(category: 'next' | 'previous', publicProfileId?: string) {
		const baseRoute = profileBaseRoute(context, publicProfileId)
		return `${baseRoute}/itineraries/${category}`
	},
})

function profileBaseRoute(context: Context, publicProfileId?: string) {
	const locale = context.i18n.locale
	let baseRoute = `/${locale}/profile`
	if (publicProfileId) {
		baseRoute = `/${locale}/profiles/${publicProfileId}`
	}
	return baseRoute
}

function newProfileBaseRoute(context: Context, publicProfileId?: string) {
	const locale = context.i18n.locale
	let baseRoute = `${process.env.NEW_PROJECT_URL}/${locale}/profile`
	if (publicProfileId) {
		baseRoute = `${process.env.NEW_PROJECT_URL}/${locale}/profiles/${publicProfileId}`
	}
	return baseRoute
}
