import Spot, { Spot2 } from './Spot'
import Marker, { Marker2 } from './Marker'
import CivitatisActivity from './CivitatisActivity'
import { serialize } from './helpers/serialize'

export interface WeatherData {
	date: string
	type:
		| 'clear_sky'
		| 'few_clouds'
		| 'scattered_clouds'
		| 'broken_clouds'
		| 'shower_rain'
		| 'rain'
		| 'thunderstorm'
		| 'snow'
		| 'mist'
		| 'unknown'
	min: number
	max: number
}

interface LocationData {
	latitude: number
	longitude: number
}

export interface DestinationLocationData {
	northeast: LocationData
	southwest: LocationData
}

export interface DestinationData {
	referenceType?: 'destination'
	id: string
	parentId?: string
	name: string
	description?: string
	address: string
	location: LocationData
	bounds?: DestinationLocationData
	cover: string
	travelersCount: number
	travelersAvatars: string[]
	type: string
	placeId?: string
	spots?: Spot[]
	spotsNextPage?: number
	spotsCategoryId?: string
	markers?: Marker[]
	weather?: WeatherData[]
	civitatis?: CivitatisActivity[]
	civitatisNextPage?: number
	civitatisTotalActivities: number
}

export interface Destination2 {
	referenceType?: 'destination'
	id: string
	parentId?: string
	name: string
	description?: string
	address: string
	location: LocationData
	bounds?: DestinationLocationData
	cover: string
	coverProvider?: string
	travelersCount: number
	travelersAvatars: string[]
	type: string
	placeId?: string
	spots?: Spot2[]
	spotsNextPage?: number
	spotsCategoryId?: string
	markers?: Marker2[]
	weather?: WeatherData[]
	civitatis?: CivitatisActivity[]
	civitatisNextPage?: number
	civitatisTotalActivities: number
	standardizedName?: string
}

export default class Destination implements DestinationData {
	private readonly _referenceType = 'destination'
	private readonly _id
	private readonly _parentId
	private _name
	private _description
	private readonly _address
	private readonly _location
	private readonly _bounds
	private readonly _cover
	private readonly _travelersCount
	private readonly _travelersAvatars
	private readonly _type
	private readonly _placeId
	private _spots
	private _spotsNextPage
	private _spotsCategoryId
	private _markers
	private _weather
	private _civitatis
	private _civitatisNextPage
	private _civitatisTotalActivities

	constructor(destination: DestinationData) {
		this._id = destination.id
		this._parentId = destination.parentId
		this._name = destination.name
		this._description = destination.description
		this._address = destination.address
		this._location = destination.location
		this._bounds = destination.bounds
		this._cover = destination.cover
		this._travelersCount = destination.travelersCount || 0
		this._travelersAvatars = destination.travelersAvatars || []
		this._type = destination.type
		this._placeId = destination.placeId
		this._spots = serialize(Spot, destination.spots)
		this._spotsNextPage = destination.spotsNextPage
		this._spotsCategoryId = destination.spotsCategoryId
		this._markers = serialize(Marker, destination.markers)
		this._weather = destination?.weather
		this._civitatisNextPage = destination?.civitatisNextPage
		this._civitatisTotalActivities = destination?.civitatisTotalActivities
		this._civitatis = serialize(CivitatisActivity, destination.civitatis)
	}

	get id(): string {
		return this._id
	}

	get parentId(): string | undefined {
		return this._parentId
	}

	get referenceType(): 'destination' {
		return this._referenceType
	}

	get name(): string {
		return this._name
	}

	set name(name: string) {
		this._name = name
	}

	get description(): string | undefined {
		return this._description
	}

	set description(description: string | undefined) {
		this._description = description
	}

	get address(): string {
		return this._address
	}

	get location(): { latitude: number; longitude: number } {
		return this._location
	}

	get bounds(): DestinationLocationData | undefined {
		return this._bounds
	}

	get cover(): string {
		return this._cover
	}

	get travelersCount(): number {
		return this._travelersCount
	}

	get travelersAvatars(): string[] {
		return this._travelersAvatars
	}

	get type(): string {
		return this._type
	}

	get placeId(): string | undefined {
		return this._placeId
	}

	get spots(): Spot[] | undefined {
		return this._spots
	}

	set spots(spots: Spot[] | undefined) {
		this._spots = spots
	}

	get spotsNextPage(): number | undefined {
		return this._spotsNextPage
	}

	set spotsNextPage(nextPage: number | undefined) {
		this._spotsNextPage = nextPage
	}

	get spotsCategoryId(): string | undefined {
		return this._spotsCategoryId
	}

	set spotsCategoryId(spotsCategoryId: string | undefined) {
		this._spotsCategoryId = spotsCategoryId
	}

	get markers(): Marker[] | undefined {
		return this._markers
	}

	set markers(markers: Marker[] | undefined) {
		this._markers = markers
	}

	get civitatis(): CivitatisActivity[] | undefined {
		return this._civitatis
	}

	set civitatis(civitatis: CivitatisActivity[] | undefined) {
		this._civitatis = civitatis
	}

	get civitatisNextPage(): number | undefined {
		return this._civitatisNextPage
	}

	set civitatisNextPage(civitatisNextPage: number | undefined) {
		this._civitatisNextPage = civitatisNextPage
	}

	get civitatisTotalActivities(): number {
		return this._civitatisTotalActivities ?? 0
	}

	set civitatisTotalActivities(totalActivities) {
		this._civitatisTotalActivities = totalActivities ?? 0
	}

	pushSpots(spots?: Spot[] | undefined, nextPage?: number): void {
		spots?.forEach((spot: Spot) => {
			const indexed = this._spots?.find(
				(indexedSpot: Spot) => indexedSpot.id === spot.id
			)
			if (!indexed) {
				this.spots?.push(spot)
			}
		})
		this._spotsNextPage = nextPage
	}

	pushCivitatis(
		civitatisActivities?: CivitatisActivity[] | undefined,
		nextPage?: number
	): void {
		civitatisActivities?.forEach((civitatisActivity: CivitatisActivity) => {
			const indexed = this.civitatis?.find(
				(indexedCivitatisActivity: CivitatisActivity) =>
					indexedCivitatisActivity.id === civitatisActivity.id
			)
			if (!indexed) {
				this.civitatis?.push(civitatisActivity)
			}
		})
		this.civitatisNextPage = nextPage
	}

	get weather(): WeatherData[] | undefined {
		return this._weather
	}

	set weather(weather: WeatherData[] | undefined) {
		this._weather = weather
	}

	toJSON(): DestinationData {
		return {
			referenceType: this.referenceType,
			id: this.id,
			parentId: this.parentId,
			name: this.name,
			description: this.description,
			address: this.address,
			location: this.location,
			bounds: this.bounds,
			cover: this.cover,
			travelersCount: this.travelersCount,
			travelersAvatars: this.travelersAvatars,
			type: this.type,
			placeId: this.placeId,
			spots: this.spots,
			spotsNextPage: this.spotsNextPage,
			spotsCategoryId: this.spotsCategoryId,
			markers: this.markers,
			weather: this.weather,
			civitatis: this.civitatis,
			civitatisNextPage: this.civitatisNextPage,
			civitatisTotalActivities: this.civitatisTotalActivities,
		}
	}
}
