import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store/index'
import Itinerary from '~/models/Itinerary'
import { ProfileService } from '~/passporter-services/profile/service'

const initState = () => ({
	ownItineraries: undefined as Itinerary[] | undefined,
	ownItinerariesNextPage: 0 as number,
	previousItineraries: undefined as Itinerary[] | undefined,
	previousItinerariesNextPage: 0 as number,
	nextItineraries: undefined as Itinerary[] | undefined,
	nextItinerariesNextPage: 0 as number,
})
export const state = initState
export type ProfileModuleState = ReturnType<typeof state>

export const mutations: MutationTree<ProfileModuleState> = {
	setOwnItineraries(state, { nextPage, itineraries }) {
		state.ownItinerariesNextPage = nextPage
		state.ownItineraries = itineraries
	},
	setPreviousItineraries(state, { nextPage, itineraries }) {
		state.previousItinerariesNextPage = nextPage
		state.previousItineraries = itineraries
	},
	setNextItineraries(state, { nextPage, itineraries }) {
		state.nextItinerariesNextPage = nextPage
		state.nextItineraries = itineraries
	},
	pushNextItineraries(state, { nextPage, itineraries }) {
		state.nextItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.nextItineraries) {
				state.nextItineraries = [itinerary]
			} else if (
				!state.nextItineraries.some((savedItinerary) => savedItinerary.id === itinerary.id)
			) {
				state.nextItineraries.push(itinerary)
			}
		})
	},
	pushOwnItineraries(state, { nextPage, itineraries }) {
		state.ownItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.ownItineraries) {
				state.ownItineraries = [itinerary]
			} else if (
				!state.ownItineraries.some((savedItinerary) => savedItinerary.id === itinerary.id)
			) {
				state.ownItineraries.push(itinerary)
			}
		})
	},
	pushPreviousItineraries(state, { nextPage, itineraries }) {
		state.previousItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.previousItineraries) {
				state.previousItineraries = [itinerary]
			} else if (
				!state.previousItineraries.some((savedItinerary) => savedItinerary.id === itinerary.id)
			) {
				state.previousItineraries.push(itinerary)
			}
		})
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<ProfileModuleState, RootState> = {
	async openMyProfile({ dispatch }) {
		try {
			const route = this.$navigation.profile.getProfile()
			window.location.href = route
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getOwnItineraries({ commit, rootState, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: 0,
				status: 'own',
			})
			commit('setOwnItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setOwnItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreOwnItineraries({ commit, rootState, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: state.ownItinerariesNextPage,
				status: 'own',
			})
			commit('pushOwnItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getPreviousItineraries({ commit, rootState, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: 0,
				status: 'previous',
			})
			commit('setPreviousItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setPreviousItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMorePreviousItineraries({ commit, rootState, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: state.previousItinerariesNextPage,
				status: 'previous',
			})
			commit('pushPreviousItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getNextItineraries({ commit, rootState, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: 0,
				status: 'next',
			})
			commit('setNextItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setNextItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreNextItineraries({ commit, rootState, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: state.nextItinerariesNextPage,
				status: 'next',
			})
			commit('pushNextItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async reset({ commit }) {
		await commit('reset')
	},
}

export const getters: GetterTree<ProfileModuleState, RootState> = {
	nextItineraries: (state) => {
		return state.nextItineraries
	},
	nextMoreItineraries: (state) => {
		return state.nextItinerariesNextPage
	},
	ownItineraries: (state) => {
		return state.ownItineraries
	},
	moreOwnItineraries: (state) => {
		return state.ownItinerariesNextPage
	},
	previousItineraries: (state) => {
		return state.previousItineraries
	},
	previousMoreItineraries: (state) => {
		return state.previousItinerariesNextPage
	},
}
