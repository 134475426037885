import { Context } from '@nuxt/types'
import { Locale } from '~/models/Locale'
import SlugService from '~/passporter-services/shared/slug'
import { TranslationService } from '~/services/Translation'

export default (context: Context) => ({
	getRoute(
		itineraryId: string,
		query?: Record<string, string>,
		section?: 'map' | 'discovery' | 'bookings'
	): string {
		const queryCollection: string[] = []

		if (query) {
			queryCollection.push(...Object.keys(query).map((key) => `${key}=${query[key]}`))
		}

		let newQuery = queryCollection.join('&')
		if (newQuery) {
			newQuery = `?${newQuery}`
		}

		return `/${context.i18n.locale}/itineraries/${itineraryId}${
			section ? `/${section}` : ''
		}${newQuery}`
	},
	getItinerary(itineraryId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}`
	},
	getItineraryMapRoute(itineraryId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/map`
	},
	getItineraryProductsRoute(itineraryId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/products`
	},
	getDestination(itineraryId: string, destinationId: string, destinationName: string): string {
		const nameSlug: string = SlugService.normalize(destinationName)
		const translatedVisit: string = TranslationService.getInstance().translate(
			'common_visit',
			context.i18n.locale as Locale
		)
		const visitSlug: string = SlugService.normalize(translatedVisit)
		return `${process.env.NEW_PROJECT_URL}/${context.i18n.locale}/itineraries/${itineraryId}/destinations/${nameSlug}/${visitSlug}-${nameSlug}/${destinationId}`
	},
	getLandmark(
		itineraryId: string,
		destinationId: string,
		landmarkId: string,
		destinationName: string
	): string {
		return `${this.getDestination(
			itineraryId,
			destinationId,
			destinationName
		)}/landmarks/${landmarkId}`
	},
	getNotesList(itineraryId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/bookings`
	},
	getOverviewPreview(itineraryId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/overview/preview`
	},
	getOverviewShared(itineraryId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/overview/shared`
	},
})
