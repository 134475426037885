
































import Vue from 'vue'
import Cover from '~/components/PassporterUI/Cover.vue'
import PictureSelector from '~/components/context/profile/PictureSelector.vue'

export default Vue.extend({
	name: 'ProfilePicture',
	components: { PictureSelector, Cover },
	props: {
		picture: {
			type: String,
			default: '',
		},
		profileEdition: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const defaultImage: string = '~/assets/avatars/avatar-person-map.svg'
		const imageUrl = this.picture as string | undefined
		const mediaId = '' as string | undefined
		return {
			imageUrl,
			defaultImage,
			mediaId,
		}
	},
	methods: {
		setImage(image?: { imageUrl: string; mediaId: string }) {
			this.imageUrl = image?.imageUrl
			this.mediaId = image?.mediaId
			this.$emit('change', image)
		},
	},
})
