























import Vue from 'vue'
import PButton from '~/components/PassporterUI/PButton.vue'

export default Vue.extend({
	name: 'InputFile',
	components: { PButton },
	props: {
		buttonName: {
			type: String,
			required: true,
		},
		styledButton: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		openFileExplorer(): void {
			const input = this.$refs.file as HTMLInputElement
			input.click()
		},
		uploadFile(): void {
			const fileInput = this.$refs.file as HTMLFormElement
			const file = fileInput.files[0]
			this.$emit('change', file)
		},
	},
})
