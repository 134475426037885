import { BaseRepository } from '../baseRepository'
import { ApiDestination } from './apiTypes'
import { DestinationMapperEntity } from './toEntity'
import { Destination2 } from '~/models/Destination'

export class DestinationRepository extends BaseRepository {
	private static resource = 'v1/destinations'

	static async getDestination(destinationId: string): Promise<Destination2> {
		const response: ApiDestination = await this.get(`${this.resource}/${destinationId}/`)
		return DestinationMapperEntity.toDestination(response)
	}

	static async find(placeId: string): Promise<Destination2> {
		const response: ApiDestination = await this.get(`${this.resource}/find/`, {
			place_id: placeId,
		})
		return DestinationMapperEntity.toDestination(response)
	}
}
