































import Vue from 'vue'
import Cover from '~/components/PassporterUI/Cover.vue'
import PictureSelector from '~/components/context/profile/PictureSelector.vue'

export default Vue.extend({
	name: 'ProfileCoverSelector',
	components: { Cover, PictureSelector },
	props: {
		isMobile: {
			type: Boolean,
			required: true,
		},
		picture: {
			type: String,
			default: '',
		},
	},
	data() {
		const defaultCover = '/img/profile/background_profile_mobile.png'
		const imageUrl = this.picture as string | undefined
		const mediaId = '' as string | undefined
		return {
			imageUrl,
			mediaId,
			defaultCover,
		}
	},
	computed: {
		defineWidth(): string {
			return this.isMobile ? '90vw' : '520px'
		},
		defineHeight(): string {
			return this.isMobile ? '170px' : '160px'
		},
	},
	methods: {
		setImage(image?: { imageUrl: string; mediaId: string }) {
			this.imageUrl = image?.imageUrl
			this.mediaId = image?.mediaId
			this.$emit('change', image)
		},
	},
})
