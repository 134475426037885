import { Context } from '@nuxt/types'
import SlugService from '~/passporter-services/shared/slug'
import { TranslationService } from '~/services/Translation'
import { Locale } from '~/models/Locale'

export default (context: Context) => ({
	getDestination(destinationId: string, destinationName: string): string {
		const nameSlug: string = SlugService.normalize(destinationName)
		const translatedVisit: string = TranslationService.getInstance().translate(
			'common_visit',
			context.i18n.locale as Locale
		)
		const visitSlug: string = SlugService.normalize(translatedVisit)
		return `${process.env.NEW_PROJECT_URL}/${context.i18n.locale}/destinations/${nameSlug}/${visitSlug}-${nameSlug}/${destinationId}`
	},
	getLandmark(destinationId: string, landmarkId: string, destinationName: string): string {
		return `${this.getDestination(destinationId, destinationName)}/landmarks/${landmarkId}`
	},
	getDestinationMap(destinationId: string): string {
		return `/${context.i18n.locale}/destinations/${destinationId}/map`
	},
	getItineraryDestinationMap(itineraryId: string, destinationId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/destinations/${destinationId}/map`
	},
})
