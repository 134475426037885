import Calendar from '../shared/calendar'
import { ApiProfile } from './apiTypes'
import { Profile } from '~/models/Profile'

export default class ProfileApiMapper {
	public static toApiAvailability(username: string): string {
		return username.toLocaleLowerCase()
	}

	public static toApiUpdateProfile(profile: Profile): FormData {
		const result = {
			username: profile.username,
			name: profile.name,
			country_id: profile.country,
			birth_date: profile.birthYear && Calendar.getFirstDateOf(profile.birthYear),
			picture_id: profile.avatarId,
			cover_id: profile.coverId,
		} as ApiProfile
		return this.toFormData(result)
	}

	public static toFormData(profile: ApiProfile): FormData {
		const formData = new FormData()
		Object.entries(profile).forEach(([key, value]) => {
			if (value) formData.append(key, value)
		})
		return formData
	}
}
