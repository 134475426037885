





































































































































































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import Divider from '~/components/PassporterUI/Divider.vue'
import PIconCalendar from '~/components/PassporterUI/Icon/PIconCalendar.vue'
import PIconDoubleMarker from '~/components/PassporterUI/Icon/PIconDoubleMarker.vue'
import PIconTrash from '~/components/PassporterUI/Icon/PIconTrash.vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import ChipGroup from '~/components/UI/ChipGroup.vue'
import DeleteDialog from '~/components/UI/DeleteDialog.vue'
import InputDateTime from '~/components/UI/InputDateTime.vue'
import Searcher from '~/components/context/discovery/Searcher.vue'
import ItineraryPrivatePolicy from '~/components/context/items/itineraries/ItineraryPrivatePolicy.vue'
import EditItineraryCover from '~/components/context/items/itineraries/configuration/EditItineraryCover.vue'
import { Destination2 as Destination } from '~/models/Destination'
import { Spot2 as Spot } from '~/models/Spot'
import { ItineraryForm } from '~/models/Itinerary'
import PIconTags from '~/components/PassporterUI/Icon/PIconTags.vue'
import Tabs, { Tab } from '~/components/PassporterUI/Tabs.vue'
import FieldStatusIcon from '~/components/context/items/itineraries/configuration/FieldStatusIcon.vue'
import { ANALYTICS_PLATFORM, AnalyticsEvent } from '~/models/Analytics'

export default Vue.extend({
	name: 'ItineraryAdministratorForm',
	components: {
		FieldStatusIcon,
		PIconTags,
		Divider,
		Searcher,
		ItineraryPrivatePolicy,
		ChipGroup,
		InputText,
		EditItineraryCover,
		InputDateTime,
		PButton,
		PIconTrash,
		DeleteDialog,
		PIconDoubleMarker,
		PIconCalendar,
		Tabs,
	},

	props: {
		value: {
			type: Object,
			default: undefined,
		} as PropOptions<ItineraryForm>,
		isRemovable: {
			type: Boolean,
			default: false,
		},
		nameApiError: {
			type: String,
			default: undefined,
		},
		startDateApiError: {
			type: String,
			default: undefined,
		},
		modified: {
			type: Boolean,
			default: false,
		},
		showEditCover: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const tabs: Array<Tab> = [
			{
				title: this.$t('common_filter_type_dates') as string,
				key: 'dates',
			},
			{
				title: this.$t('common_filter_type_duration') as string,
				key: 'duration',
			},
		]
		return {
			isTouched: this.modified,
			showConfirmDeleteDialog: false,
			timePeriod: [
				{
					id: '2',
					name: this.$t('B2C_itinerarycreation_step1_selector_period_weekend'),
				},
				{
					id: '7',
					name: this.$t('B2C_itinerarycreation_step1_selector_period_oneweek'),
				},
				{
					id: '14',
					name: this.$t('B2C_itinerarycreation_step1_selector_period_twoweeks'),
				},
			],
			tabs,
		}
	},
	computed: {
		...mapGetters({
			itineraryOwner: 'itinerary/itineraryOwner',
			screenSize: 'screenSize',
		}),
		form(): ItineraryForm {
			return this.value
		},
		modify: {
			get(): boolean {
				return this.isTouched
			},
			set(value: boolean): void {
				this.isTouched = value
				this.$emit('update:modified', value)
			},
		},
		dateError: {
			get(): string {
				return this.startDateApiError
			},
			set(value: string | undefined): void {
				this.$emit('update:startDateApiError', value)
			},
		},
		nameError: {
			get(): string {
				return this.nameApiError
			},
			set(value: string | undefined): void {
				this.$emit('update:nameApiError', value)
			},
		},
		travelName: {
			get(): string {
				return this.form.name as string
			},
			set(value: string): void {
				this.form.name = value
			},
		},
		showDate: {
			get(): boolean {
				return this.form.showDate
			},
			set(value: boolean): void {
				this.form.showDate = value
			},
		},
		startDate: {
			get(): string {
				return this.form.startDate as string
			},
			set(value: string): void {
				this.form.startDate = value
				if (value) {
					this.hideTimePeriod()
				}
			},
		},
		endDate: {
			get(): string {
				return this.form.endDate as string
			},
			set(value: string): void {
				this.form.endDate = value
				if (value) {
					this.hideTimePeriod()
				}
			},
		},
		destination: {
			get(): Destination | undefined {
				return this.form.destination
			},
			set(value: Destination | undefined): void {
				this.form.destination = value
			},
		},
		travelDays: {
			get(): number | undefined {
				return this.form.numDays
			},
			set(value: number): void {
				this.form.numDays = value
				if (value) {
					this.hideDates()
				}
			},
		},
		hasPrivatePolicy: {
			get(): boolean {
				return this.form.isPrivate
			},
			set(value: boolean): void {
				this.form.isPrivate = value
			},
		},
		hasDatesOrTimePeriod(): boolean {
			const dates = Boolean(this.form?.startDate && this.form?.endDate)
			return dates || Boolean(this.form.numDays)
		},
	},
	mounted() {
		const isEditionForm = this.form.id
		if (!isEditionForm) {
			this.addViewTracker()
		}
		this.addViewSettingsTracker()
	},
	methods: {
		hideDates() {
			this.endDate = ''
			this.startDate = ''
		},
		hideTimePeriod() {
			this.travelDays = undefined
		},
		clean() {
			this.form.startDate = undefined
			this.form.endDate = undefined
		},
		changeDate() {
			this.dateError = undefined
			this.modify = true
		},
		selectDestination(place: Destination | Spot | undefined) {
			this.modify = true
			this.nameError = undefined

			let result = place
			if (place?.referenceType === 'spot') {
				result = undefined
			}
			this.destination = result as Destination
		},
		changeTravelName() {
			this.modify = true
		},
		searchError(error: any) {
			if (error.message === '400') {
				this.nameError = String(this.$t('b2b_optimize_end_obligatory'))
			}
		},
		focusDate() {
			this.$mixpanel?.track('Create Itinerary Calendar impression')
		},
		deleteItinerary() {
			this.showConfirmDeleteDialog = true
		},
		async confirmDelete() {
			try {
				await this.$store.dispatch('itinerary/deleteItinerary', this.form.id)
				this.closeDeleteDialog()
				this.closeMainDialog()
				await this.showDeleteAlert()
			} catch (e: any) {
			} finally {
				this.openItinerariesView()
			}
		},
		closeDeleteDialog() {
			;(this.$refs.deleteDialog as any)?.close()
		},
		async showDeleteAlert(): Promise<void> {
			await this.$store.dispatch('alerts/setAlert', {
				type: 'delete',
				text: this.$t('T_Deleteitinerary_confirmation'),
			})
		},
		closeMainDialog(): void {
			this.$emit('update:deleteItinerary')
		},
		openItinerariesView(): void {
			const route = this.$navigation.profile.getProfile()
			window.location.href = route
		},
		addViewTracker() {
			const params: Record<string, string> = {
				action: 'view wizard',
				element: 'platform',
				value: 'web',
			}
			this.$tracker.event('view', params)
		},
		addViewSettingsTracker() {
			const context = this.$tracker.getContext(this.$route?.name) || ''
			if (context !== 'trip') return

			const params = {
				element: 'settings',
				platform: ANALYTICS_PLATFORM,
				context,
				client: 'passporter',
				id: this.value?.id || '',
				destination: this.destination?.name.toLowerCase() || '',
			}
			this.$tracker.event(AnalyticsEvent.VIEW, params)
		},
	},
})
