import axios, { AxiosInstance } from 'axios'
import { CookieService } from '~/passporter-services/cookies'
import EventBus from '~/infrastructure/eventBus'
import { BaseHttpClient, HttpClientResponse } from '~/passporter-services/baseRepository'

export class HttpClient implements BaseHttpClient {
	private client: AxiosInstance
	private static instance: HttpClient

	private constructor() {
		this.client = axios.create({ baseURL: this.getBaseUrl() })
		this.setupInterceptors()
	}

	public static getInstance(): HttpClient {
		if (!this.instance) this.instance = new HttpClient()
		return this.instance
	}

	public get<T>(
		url: string,
		params?: Record<string, any>,
		headers?: Record<string, string>
	): Promise<HttpClientResponse<T>> {
		return this.client.get(url, { params, headers: this.getHeaders(headers) })
	}

	public post<T>(
		url: string,
		body: any,
		params?: Record<string, any>,
		headers?: Record<string, string>
	): Promise<HttpClientResponse<T>> {
		return this.client.post(url, body, { params, headers: this.getHeaders(headers) })
	}

	public patch<T>(
		url: string,
		body: any,
		params?: Record<string, any>,
		headers?: Record<string, string>
	): Promise<HttpClientResponse<T>> {
		return this.client.patch(url, body, { params, headers: this.getHeaders(headers) })
	}

	public delete<T>(url: string, headers?: Record<string, string>): Promise<HttpClientResponse<T>> {
		return this.client.delete(url, { headers: this.getHeaders(headers) })
	}

	private setupInterceptors(): void {
		this.client.interceptors.response.use(
			(response) => response,
			(error) => {
				return this.manageError(error)
			}
		)
	}

	private getHeaders(headers?: Record<string, string>): Record<string, string> {
		return {
			'Accept-Language': this.getLanguage(),
			'Authorization': `Bearer ${this.getToken()}`,
			...headers,
		}
	}

	private manageError(error: any): Promise<void> {
		const IGNORED_PATHS = ['auth/login/', 'auth/logout/', 'auth/refresh/']
		const isIgnored = IGNORED_PATHS.some((path) => error.config.url?.includes(path))
		const statusCode = error?.response?.status
		const notAuthenticatedStatus = statusCode === 401 || statusCode === 422

		return new Promise((_resolve, reject) => {
			if (notAuthenticatedStatus && !isIgnored) EventBus.$emit('clear-auth')
			return reject(error)
		})
	}

	private getLanguage(): string {
		return CookieService.getCookie('lang')
	}

	private getToken(): string {
		const authCookie = CookieService.getCookie('authentication-cookie')
		const parsed = JSON.parse(decodeURIComponent(authCookie))
		return parsed.auth?.accessToken
	}

	private getBaseUrl(): string {
		return process.env.API_BASE_URL!
	}
}
