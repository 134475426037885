import { render, staticRenderFns } from "./ProfileTripsView.vue?vue&type=template&id=dcdd3078&scoped=true&"
import script from "./ProfileTripsView.vue?vue&type=script&lang=ts&"
export * from "./ProfileTripsView.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileTripsView.vue?vue&type=style&index=0&id=dcdd3078&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcdd3078",
  null
  
)

export default component.exports