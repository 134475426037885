import { BaseRepository } from '~/passporter-services/baseRepository'
import { SiteEntityMapper } from './entityMapper'
import { Block2 } from '~/models/Block'
import { Destination2 } from '~/models/Destination'
import ParamsBuilder from '../shared/paramsBuilder'
import { BlockEntityMapper } from '../block/entityMapper'
import { Site2 } from '~/models/Site'
import Product from '~/models/Product'

export class SiteRepository extends BaseRepository {
	static readonly resource = 'v1/sites/'

	static async getSite(siteId: string): Promise<Site2> {
		const response: any = await this.get(`${this.resource}${siteId}/`)
		return SiteEntityMapper.toSite(response)
	}

	static async getDestinationBlocks(id: string): Promise<Block2<Destination2>[]> {
		const response: any = await this.get(`${this.resource}${id}/blocks/`, {
			page: 0,
			page_size: 10,
			type: 'destination',
		})
		return BlockEntityMapper.toBlocks(response.results) as Block2<Destination2>[]
	}

	static async getProducts(siteId: string): Promise<Block2<Product>[]> {
		const paramsBuilder = new ParamsBuilder()
		const params = paramsBuilder.obtainParams({ type: 'product' })
		const response: any = await this.get(`${this.resource}${siteId}/blocks?${params}`)
		if (!response.results && !response.results.length) return []

		return BlockEntityMapper.toBlocks(response.results) as Block2<Product>[]
	}
}
