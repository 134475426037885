


























































import Vue from 'vue'
import POverlay from './POverlay.vue'
import Cover from './Cover.vue'
import PButton from './PButton.vue'

export default Vue.extend({
	name: 'Wizard',
	components: { POverlay, Cover, PButton },
	props: {
		disabledContinue: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		imageUrl: {
			type: String,
			default: '',
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			step: 0,
			headerHeight: '207px',
			lastTouchY: undefined as number | undefined,
		}
	},
	computed: {
		slots(): Array<string> {
			return Object.keys(this.$slots)
		},
		isLastStep(): boolean {
			return this.step + 1 === this.slots.length
		},
	},
	methods: {
		goToPreviousStep(): void {
			this.step = this.step - 1
			this.$emit('stepChange', this.step)
		},
		goToNextStep(): void {
			if (this.isLastStep) {
				this.$emit('submit')
				return
			}
			this.step = this.step + 1
			this.$emit('stepChange', this.step)
		},
	},
})
